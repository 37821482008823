<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
      </div>
      <app-main />
      <div class="bottomInfo">
        <div class="info">
          <div>2021 © 成都比昂科技有限公司. All Rights Reserved. v 1.0</div>
        </div>
      </div>

      <el-dialog
      :visible.sync="open"
      width="414px"
      :close-on-click-modal="false"
      center
      >
          <span slot="title" class="dialog-title">
            验证
          </span>

          <div style="display:flex">
            <el-input v-model="pwd" placeholder="请输入验证密码" style="margin-right:10px;" clearable></el-input>
            <el-button type="success" @click="submit">确认</el-button>
          </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import moment from 'moment'
export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  data(){
    return {
      open:false,
      pwd:'',
    }
  },
  mounted(){
    // 中性化平台 打开验证弹窗
    this.$bus.$on('isOpenModel',res=>{
      console.log("res",res)
      this.open = res
      this.pwd = ''
    })
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    // 中性化平台验证码提交
    submit(){
      let code = moment(new Date()).format('DD-MM')
      console.log("code",code );
      if(code == this.pwd){
        this.open = false
        this.$bus.$emit('allowSkip',true)

      }else{
        this.$message({
          message: '验证码错误',
          type: 'error',
          duration: 2 * 1000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .sidebar-container{
    display: flex;
    flex-direction: column;
  }

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }

  .bottomInfo{
    color:#fff;
    font-size: 14px;
    background-color: #eeeff3;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    .info{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
