import { asyncRoutes, constantRoutes } from '@/router/manageCenter'
import Layout from '@/layout/index'

/**
 * Use meta.role to determine if the current user has permission  使用meta.role确定当前用户是否具有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion  通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
      const tmp = { ...route }
      if (hasPermission(roles, tmp)) {
        if (tmp.children) {
          tmp.children = filterAsyncRoutes(tmp.children, roles)
        }
        res.push(tmp)
      }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    console.log("routes",routes)
    state.routes = constantRoutes.concat(routes)
    console.log("state.routes",state.routes)
  }
}


// 根据登录用户来增加系统设置路由配置
const actions = {
  generateRoutes({ commit }) {
    console.log("asyncRoutes======管理中心====",asyncRoutes);
    return new Promise(resolve => {
      let accessedRoutes
      if(JSON.parse(sessionStorage.getItem('userInfo')).account != 'ceshi'){//测试账号才展示管理中心的信息数据管理菜单
        let arr = []
        asyncRoutes.forEach(val=>{
          if(val.path == '/manageCenter'){
            console.log("val.children.filter(v=>v.path!='infoAndDataManage')",val.children.filter(v=>v.path!='infoAndDataManage'));
            val.children = val.children.filter(v=>v.path!='infoAndDataManage')
          }
        })
        arr = asyncRoutes
        accessedRoutes = filterAsyncRoutes(arr)
        console.log("arr",arr);
      }else{
        accessedRoutes = filterAsyncRoutes(asyncRoutes)
      }
      
      
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
