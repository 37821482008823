<template>
<!-- 左侧导航上半部logo -->
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <div v-if="collapse" key="collapse" class="sidebar-logo-link"  @click="goPage()">
        <img v-if="logo" :src="config.companyLogo?config.companyLogo:logo" class="sidebar-logo">
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </div>
      <div v-else key="expand" class="sidebar-logo-link"  @click="goPage()">
        <img v-if="logo" :src="config.companyLogo?config.companyLogo:logo" class="sidebar-logo" >
        <p class="sidebar-title">{{ config.companyName ?config.companyName:title}}</p>
        <p class="sidebar-p">欢迎登录{{config.name?config.name:'绿色防控平台'}}</p>
        <!-- <p class="sidebar-p">{{platform}}</p> -->
        <p class="sidebar-layout" @click="layerOut">退 出</p>
      </div>
    </transition>

  

      
  </div>
</template>

<script>
import store from '@/store'
// import Page404 from '../../../views/404.vue'
var url = top.window.location.href
var str = url.substring(0, url.indexOf('dist/index.html')) + 'login.html'
export default {
  name: 'SidebarLogo',
  // components: { Page404 },
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      // title: null,
      logo: '/logo.png',
      config:{
        name:'',
        companyName:'',
        companyLogo:'',
      },
    }
  },
  computed: {
    title: function() {
      return JSON.parse(sessionStorage.getItem('userInfo')).account
    }
  },
  created(){
    this.config = JSON.parse(sessionStorage.getItem('config'))?JSON.parse(sessionStorage.getItem('config')):{}
    // 修改平台标题 logo
    this.$bus.$on('edit_title',platform=>{
      console.log("platform  收到",platform);
      this.config = JSON.parse(sessionStorage.getItem('config'))
    })

    // 中性化平台 接受是否允许跳转
    this.$bus.$on('allowSkip',res=>{
      if(res){
        this.$router.push({path:'/neutralizeConfig/index'})
      }
    })
  },
  methods: {
    layerOut() {
      sessionStorage.removeItem('access_token')
      sessionStorage.removeItem('userInfo')
      // sessionStorage.removeItem('photoId')
      // sessionStorage.removeItem('menus')
      sessionStorage.removeItem('config')
      sessionStorage.removeItem('userId')
      // 退出时清空权限数组
      this.$store.commit('user/DEL_ROLES',{roles:[]})
      console.log("store.getters.roles 退出",store.getters.roles);
      console.log("str",str);
      // top.window.location.href = str
      this.$router.push({path:'/login'})
    },

    goPage(){
      this.$bus.$emit('isOpenModel',true);
      
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  /*height: 50px;*/
  /*line-height: 50px;*/
  /*background: #2b2f3a;*/
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 120px;
      // height: 123px;
      vertical-align: middle;
      /*margin-right: 12px;*/
      display: block;
      margin: auto;
      margin-top: 16px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: $base_color;
      font-weight: 600;
      line-height: 28px;
      font-size: 18px;
      font-family: FZLanTingHei-DB-GBK,Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
        // margin-top: -10px;
    }
    & .sidebar-p{
      /*display: inline-block;*/
      margin: 0;
      color: $base_color_grey;
      font-weight: 600;
      line-height: 21px;
      font-size: 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
      & .sidebar-layout{
          color: #888888;
          font-weight: 600;
          line-height: 22px;
          font-size: 11px;
          font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
          vertical-align: middle;
          border: 1px solid #DCDCDC;
          border-radius: 20px;
          display: inline-block;
          padding: 0 20px;
          margin: 20px 0 50px;
      }
  }

  &.collapse {
    .sidebar-logo {
      /*margin-right: 0px;*/
        width: 33px;
        height: 37px;
        margin: 10px auto;
    }
  }
}
</style>
