
const tokens = {
  admin: {
    token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1ODUxOTM3MDAsInVzZXJfbmFtZSI6eyJwYXNzd29yZCI6bnVsbCwidXNlcm5hbWUiOiJjZGJleW9uZCIsImF1dGhvcml0aWVzIjpbeyJhdXRob3JpdHkiOiJjdXN0b21lciJ9XSwiYWNjb3VudE5vbkV4cGlyZWQiOnRydWUsImFjY291bnROb25Mb2NrZWQiOnRydWUsImNyZWRlbnRpYWxzTm9uRXhwaXJlZCI6dHJ1ZSwiZW5hYmxlZCI6dHJ1ZSwicmVhbG5hbWUiOiLmiJDpg73mr5TmmILnp5HmioDmvJTnpLrotKblj7ciLCJhZGRyZXNzIjoi5pqC5pegIiwibW9iaWxlIjoiMTgxNjAwMTg4MzgifSwiYXV0aG9yaXRpZXMiOlsiY3VzdG9tZXIiXSwianRpIjoiMzc0NTgwMzUtZTY1Ny00ZDA2LTgwNGUtMDY4OTlmNDBiMzU5IiwiY2xpZW50X2lkIjoiYmV5b25kMiIsInNjb3BlIjpbImFsbCJdfQ.y_YOyYQeg4jDjTuD09_HXBojgCa3S9tDGi-G-AC7-vw'
  },
  editor: {
    token: 'editor-token'
  }
}

const users = {
  'admin-token': {
    roles: ['admin'],
    introduction: 'I am a super administrator',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Super Admin'
  },
  'editor-token': {
    roles: ['editor'],
    introduction: 'I am an editor',
    avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
    name: 'Normal Editor'
  }
}

export default [
  // user login
  {
    url: '/user/login',
    type: 'post',
    response: config => {
      const { username } = config.body
      const token = tokens[username]

      // mock error
      if (!token) {
        return {
          code: 60204,
          message: 'Account and password are incorrect.'
        }
      }

      return {
        code: 200,
        data: token
      }
    }
  },

  // get user info
  {
    url: '/user/info\.*',
    type: 'get',
    response: config => {
      // const { token } = config.query
      // const info = users[token]
      const info = users['admin-token']

      // mock error
      if (!info) {
        return {
          code: 50008,
          message: 'Login failed, unable to get user details.'
          // message: config.query
        }
      }

      return {
        code: 200,
        data: info
      }
    }
  },

  // user logout
  {
    url: '/user/logout',
    type: 'post',
    response: _ => {
      return {
        code: 200,
        data: 'success'
      }
    }
  }
]
