<template>
  <!-- 远程信息化虫情测报系统-导航 -->
  <div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        v-for="(item, index) in cRoutes"
        :key="index"
        v-if="!item.hidden"
        :index="index.toString()"
        >{{ item.name }}</el-menu-item
      >
    </el-menu>
  </div>
</template>

<script>
// import pathToRegexp from 'path-to-regexp'
const pathToRegexp = require("path-to-regexp");

export default {
  data() {
    return {
      activeIndex: "0",
      cRoutes: []
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      const thisRoute = this.$route;
      // console.log("thisRoute",thisRoute);
      const pRoutes = this.$store.state.permission.routes;
      // console.log("this.$store.state",this.$store.state);
      // console.log("pRoutes",pRoutes);
      const cRoutes = [];
      pRoutes.forEach(item => {
        if (thisRoute.matched[0].path == item.path) {
          //                cRoutes = item.children
          item.children.forEach(cItem => {
            if (!cItem.hidden) {
              cRoutes.push(cItem);
            }
          });
        }
      });
      this.cRoutes = cRoutes;
      // console.log("cRoutes",cRoutes);
      cRoutes.forEach((item, index) => {
        if (this.$route.path.split("/")[2] == item.path) {
          this.activeIndex = index.toString();
          //                this.activeIndex = index
        }
      });
    },
    pathCompile(path) {
      // To solve this problem 来解决这个问题  https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route;
      var toPath = pathToRegexp.compile(path);
      // console.log("pathToRegexp.compile",pathToRegexp.compile);
      // console.log("pathToRegexp",pathToRegexp);
      // console.log("topath",toPath);
      return toPath(params);
    },
    handleLink(item) {
      const { redirect, path } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    },
    handleSelect(e) {
      //e : 二级导航的索引
      this.handleLink(this.cRoutes[e]);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
.el-menu-item {
  padding: 0 40px;
  font-size: 18px;
  font-family: Microsoft YaHei;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid $base_color;
  color: $base_color;
}
</style>
