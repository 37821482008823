import requestMock from '@/utils/requestMock'
import request from '@/utils/request'
const userBase = 'fourmood-web'
// import axios from 'axios'

// /*获得设备列表*/
export function getDev(params) {
  return request({
    url: userBase + '/condition/setting/query',
    method: 'GET',
    params
  })
}
// /*打开开关-关闭*/
export function gotSwitch(params) {
  return request({
    url: userBase + '/condition/setting/opt/' + params.id,
    method: 'POST',
    params
  })
}
// /*获取配置*/
export function gotConfig(params) {
  return request({
    url: userBase + '/condition/setting/config',
    method: 'GET',
    params
  })
}
// /*用户对应设备列表*/
export function gotDevices(params) {
  return request({
    url: userBase + '/condition/setting/devices',
    method: 'GET',
    params
  })
}
// /*新增设备列表*/
export function addDev(params) {
  return request({
    url: userBase + '/condition/setting/add',
    method: 'POST',
    data: params
  })
}
export function modDev(params) {
  return request({
    url: userBase + '/condition/setting/modify',
    method: 'PUT',
    data: params
  })
}
// /*删除配置*/
export function delDev(params) {
  return request({
    url: userBase + '/condition/setting/del',
    method: 'DELETE',
    params
  })
}
// /*获得提醒配置*/
export function getWarn(params) {
  return request({
    url: userBase + '/warn/config',
    method: 'GET',
    params
  })
}
// /*新增提醒配置*/
export function addWarn(params) {
  return request({
    url: userBase + '/warn/config',
    method: 'POST',
    data: params
  })
}
// /*新增提醒配置*/
export function modWarn(params) {
  return request({
    url: userBase + '/warn/config',
    method: 'PUT',
    data: params
  })
}
// /*新增提醒配置*/
export function gotHistory(params) {
  return request({
    url: userBase + '/condition/setting/history',
    method: 'GET',
    params
  })
}
