<template>
  <!-- 头部 -->
  <div class="navbar 112233">
    <div class="nav-h">
      <hamburger
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />
      <div class="nav-sub">
        <div class="sub">
          <a @click="goHome" v-if="config.name">{{ config.name }}</a>
          <a @click="goHome" v-else>绿色云农—比昂绿色防控<span>AI</span>平台</a>
        </div>
      </div>
      <div class="right-menu">
        <div
          class="manageCenter"
          @click="goManageCenter"
          v-if="account != '乃渠镇花椒园区'&&account != '三台县农业局植保站'"
        >
          <!-- <router-link target="_blank" to="/manageCenter/peopleManage">管理中心</router-link> -->
          管理中心
        </div>
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <span>指挥中心</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <!--<router-link to="/">-->
            <!--<el-dropdown-item>-->
            <!--地图防控-->
            <!--</el-dropdown-item>-->
            <!--</router-link>-->

            <el-dropdown-item>
              <span style="display: block">
                <a target="_blank" :href="mapPath">地图防控</a>
              </span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="
                account != '苍溪县陵江镇人民政府' &&
                account != '乃渠镇花椒园区' &&
                account != '古蔺县太平镇人民政府'&&account == 'cdbeyond'
              "
            >
              <span style="display: block">
                <a
                  target="_blank"
                  href="http://iot.cdbeyond.com/crzx/index.html"
                  >蒲江基地</a
                >
              </span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="
                account != '苍溪县陵江镇人民政府' &&
                account != '乃渠镇花椒园区' &&
                account != '古蔺县太平镇人民政府'&&account == 'cdbeyond'
              "
            >
              <span style="display: block">
                <a
                  target="_blank"
                  href="http://iot.cdbeyond.com/green/index.html#/Home"
                  >绿色防控中心</a
                >
              </span>
            </el-dropdown-item>
            <el-dropdown-item
              v-if="
                account != '乃渠镇花椒园区' && account != '古蔺县太平镇人民政府'
              "
            >
              <span style="display: block">
                <a href="#/alarmL/config">报警系统</a>
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <span>帮助中心</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item> 帮助1 </el-dropdown-item>
            </router-link>
            <el-dropdown-item> 帮助2 </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <div class="fix_div"></div>
    <breadcrumb v-if="homeKey" class="breadcrumb-container" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
const mapArr = {
  桐梓县农业农村局: "/tz/index.html",
};
export default {
  name: "navbar",
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar"]),
    homeKey() {
      // console.log("this.$route.pathaaaaaaaaaaaaaaa", this.$route.path);
      let arr = this.$route.path.split("/");
      // console.log("arr", arr);
      if (arr.includes("home") || arr.includes("manageCenter")) {
        return false;
      } else {
        return true;
      }
    },
    mapPath: function () {
      const account = JSON.parse(sessionStorage.getItem("userInfo")).account;
      // console.log("account",account);
      if (account == "重庆大观茶业有限公司") {
        return "/daguan/index.html";
      } else if (account == "东海县房山镇人民政府") {
        return "/donghai/index.html";
      } else if (account == "东海县洪庄镇人民政府") {
        return "/donghai/index.html";
      } else if (account == "东海县农业农村局") {
        return "/donghai/index.html";
      } else if (account == "蒲江县铜鼓村智慧农业") {
        return "/tonggu/index.html";
      } else if (account == "云阳腾玛人工智能") {
        return "/yunyang/index.html";
      } else if (account == "三台县农业农村局植保站") {
        return "/santai/index.html";
      } else if (account == "徐州泰硕药材种植有限公司") {
        return "/xuzhou1/index.html";
      } else if (account == "苍溪县陵江镇人民政府") {
        return "/cangxi1/index.html";
      } else if (account == "四川科耐科技有限公司") {
        //古蔺
        return "/gulin/index.html";
      } else if (account == "马边彝族自治县农业农村局") {
        return "/mabian/index.html";
      } else if (account == "三江汇元禾稻鱼耦合项目") {
        return "/daping/sanjiang/index.html";
      } else if (account == "乃渠镇花椒园区") {
        return "/daping/naiqu/index.html";
      } else if (account == "陆良县植保植检站") {
        return "/daping/luliang/index.html";
      } else if (account == "古蔺县太平镇人民政府") {
        return "/daping/gulin/index.html";
      } else if(account == '双柏县植保植检站'){
        return '/daping/shuangbai/index.html'
      }else if(account == '叙州区农业农村局'){
        return '/daping/xuzhou01/index.html'
      }else if(account == '洱源县植保植检站'){
        return '/eryuan/index.html'
      }

      return mapArr[account] ? mapArr[account] : "/map/index.html";
      // return mapArr[account] ? mapArr[account] : '/tz/index.html'
    },
  },
  data() {
    return {
      config: null,
      account: "",
    };
  },
  created() {
    this.account = JSON.parse(sessionStorage.getItem("userInfo")).account;
    this.config = JSON.parse(sessionStorage.getItem("config"))
      ? JSON.parse(sessionStorage.getItem("config"))
      : {};
    // 修改平台标题 logo
    this.$bus.$on("edit_title", (platform) => {
      console.log("platform  收到", platform);
      this.config = JSON.parse(sessionStorage.getItem("config"));
    });
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    goHome() {
      this.$router.push({ path: "/home/index" });
    },
    // 跳转到管理中心
    goManageCenter() {
      this.$router.push({ path: "/manageCenter/peopleManage" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.navbar {
  /*height: 50px;*/
  /*overflow: hidden;*/
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    /*float: left;*/
    clear: both;
    display: block;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    &:focus {
      outline: none;
    }
    // 管理中心
    .manageCenter {
      font-size: 14px;
      color: #606266;
      margin-right: 30px;
      &:hover {
        cursor: pointer;
      }
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
.nav-h {
  box-shadow: 1px 2px 1px 0px rgba(216, 217, 221, 0.26);
  border-bottom: 1px solid rgba(216, 217, 221, 0.26);
  overflow: hidden;
}
.nav-sub {
  font-size: 19.05px;
  color: $base_color;
  line-height: 47px;
  font-family: FZLanTingHei-EB-GBK;
  font-weight: 600;
  float: left;
  .sub {
    span {
      color: $base_color_light;
      font-size: 28px;
      vertical-align: bottom;
    }
  }
}
/*alarm样式兼容*/
.alarm_lock_nav {
  .fix_div {
    border-bottom: 1px solid #d8d9dd;
  }
  .nav-h {
    margin: auto;
    max-width: 1200px;
    box-shadow: none;
    border-bottom: 0;
  }
  .breadcrumb-container {
    margin: auto;
    max-width: 1200px;
  }
}
</style>
