import { asyncRoutes, constantRoutes } from "@/router/index3";
import Layout from "@/layout/index";

/**
 * Use meta.role to determine if the current user has permission  使用meta.role确定当前用户是否具有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion  通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

// 根据登录用户来增加系统设置路由配置
const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {

      asyncRoutes.forEach(val=>{
        if(val.path == '/wisdomTree_'){
          val.children = val.children.filter(v=>v.name != '流量卡管理')
        }
      })
      asyncRoutes.forEach(val=>{
        if(val.path == '/wisdomTree_'){
          val.children = val.children.filter(v=>v.name != '频振诱控系统'&&v.name != '天敌防控系统'&&v.name != '微生物喷雾系统'&&v.name != '设施农业测控系统')
        }
      })
      let account
      if(sessionStorage.getItem('userInfo')){
         account = JSON.parse(sessionStorage.getItem('userInfo')).account
      }
      let accessedRoutes;
      // 雅安一凡
      if(sessionStorage.getItem('userPara') == 'hejiang001'){
        console.log("111111111111111");
        let arr = []
        let pathList = ['/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/worms','/sexTelegraph','/high','/el','/worms_dfs','/dsxj']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v=>{
          // 远程信息化虫情测报系统
          if(v.path == '/telemeter'){
            v.children = v.children.filter(value=>{
              return value.path == '/sexTelegraph'
            })
          }
        })
        accessedRoutes = arr
      }else if(account == '苍溪县陵江镇人民政府'){//苍溪
        let arr = []
        let pathList = ['/alarmL','/alarmL/config','/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/worms','/seedling_','/seedling','/lyrical_','/lyrical','/weather_','/weather','/manageCenter']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v=>{
          // 远程信息化虫情测报系统
          if(v.path == '/telemeter'){
            v.children = v.children.filter(value=>{
              return value.path == '/worms'
            })
          }
          // 苗情
          if(v.path == '/seedling_'){
            v.children = v.children.filter(value=>{
              return value.path == '/seedling'
            })
          }
          // 墒情
          if(v.path == '/lyrical_'){
            v.children = v.children.filter(value=>{
              return value.path == '/lyrical'
            })
          }
          // 气象
          if(v.path == '/weather_'){
            v.children = v.children.filter(value=>{
              return value.path == '/weather'
            })
          }
        })
        accessedRoutes = arr


      }else if(sessionStorage.getItem('userPara') == 'aiot001'){//艾欧特
        let arr = []
        let pathList = ['/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/sexTelegraph']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v=>{
          // 远程信息化虫情测报系统
          if(v.path == '/telemeter'){
            v.children = v.children.filter(value=>{
              return value.path == '/sexTelegraph'
            })
          }
        })
        accessedRoutes = arr
      }else if(account == '乃渠镇花椒园区') {//乃渠镇花椒园区
        let arr = []
        let pathList = ['/alarmL','/alarmL/config','/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/sexTelegraph','/seedling_','/seedling','/lyrical_','/lyrical','/weather_','/weather','/lamp_','/lamp','/lamp_un','/manageCenter']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        console.log("arr =================",arr);
        arr.forEach(v=>{
          // 远程信息化虫情测报系统
          if(v.path == '/telemeter'){
            v.children = v.children.filter(value=>{
              return value.path == '/sexTelegraph'
            })
          }
          // 苗情
          if(v.path == '/seedling_'){
            v.children = v.children.filter(value=>{
              return value.path == '/seedling'
            })
          }
          // 墒情
          if(v.path == '/lyrical_'){
            v.children = v.children.filter(value=>{
              return value.path == '/lyrical'
            })
          }
          // 气象
          if(v.path == '/weather_'){
            v.children = v.children.filter(value=>{
              return value.path == '/weather'
            })
          }
          // 杀虫灯
          // if(v.path == '/lamp_'){
          //   v.children = v.children.filter(value=>{
          //     return value.path == '/lamp'
          //   })
          // }
          // if(v.path == '/lamp_'){
          //   v.children = v.children.filter(value=>{
          //     return value.path == '/lamp_un'
          //   })
          // }
        })
        accessedRoutes = arr
      }else if(account == '三江汇元禾稻鱼耦合项目') {//三江汇元禾稻鱼耦合项目
          let arr = []
        let pathList = ['/alarmL','/alarmL/config','/home','/home/index','/neutralize_Config','/neutralizeConfig','/seedling_','/seedling','/lyrical_','/lyrical','/weather_','/waterMonitor','/weather','/lamp_','/lamp_un','/wisdomTree_','/wisdomTree','/manageCenter','/wf_']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v=>{
          // 苗情
          if(v.path == '/seedling_'){
            v.children = v.children.filter(value=>{
              return value.path == '/seedling'
            })
          }
          // 智慧树  捕鸟器
          if(v.path == '/wisdomTree_'){
            v.children = v.children.filter(value=>{
              return value.path == '/wisdomTree'
            })
          }
          // 杀虫灯
          if(v.path == '/lamp_'){
            v.children = v.children.filter(value=>{
              return value.path != '/lamp'
            })
          }
        })
        accessedRoutes = arr
      }else if(account == '古蔺县太平镇人民政府') {//古蔺县太平镇人民政府
         let arr = []
        let pathList = ['/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/worms','/seedling_','/seedling','/lyrical_','/lyrical','/weather_','/waterMonitor','/weather','/lamp_','/lamp_un','/manageCenter']
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v => {
          // 远程信息化虫情测报系统
          if(v.path == '/telemeter'){
            v.children = v.children.filter(value=>{
              return value.path == '/worms'
            })
          }
          // 气象
          if(v.path == '/weather_'){
            v.children = v.children.filter(value=>{
              return value.path == '/weather'
            })
          }
          // 苗情
          if(v.path == '/seedling_'){
            v.children = v.children.filter(value=>{
              return value.path == '/seedling'
            })
          }
          // 杀虫灯
          if(v.path == '/lamp_'){
            v.children = v.children.filter(value=>{
              return value.path != '/lamp'
            })
          }
        })
        accessedRoutes = arr
      }else if(account == '蒲江县铜鼓村智慧农业'){//蒲江县铜鼓村智慧农业
        let arr = []
        let pathList = ['/spore_','/weatherd','/wisdomTree_']
        asyncRoutes.forEach(val=>{
          if(val.path&& !pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(val=>{
          if(val.path == '/telemeter'){
            console.log("val.children",val.children);
            val.children = val.children.filter(value=>{
              return value.path == '/worms'||value.path == '/sexTelegraph'||value.path == '/high'
            })
          }
        })

        accessedRoutes = arr
      }else if(account == 'ceshi'){//ceshi
        asyncRoutes.forEach(val=>{
          if(val.path == '/wisdomTree_'){
            val.children.push(
              {
                path: '/flowRateCardManage',
                redirect: '/flowRateCardManage/equip',
                name: '流量卡管理',
                meta: { title: '流量卡管理', icon: 'el' }
              }
            )
          }
        })
        accessedRoutes = asyncRoutes
      }else if(account == '玉龙县植保植检站'||account == '洱源县植保植检站'||account == '双柏县植保植检站'||account == '陆良县植保植检站'){
        
        asyncRoutes.forEach(val=>{
          if(val.path == '/wisdomTree_'){
            val.children.push(
              {
                path: '/pzykxt',
                redirect: '/pzykxt/equip',
                name: '频振诱控系统',
                meta: { title: '频振诱控系统', icon: 'el' }
              },
              {
                path: '/tdfkxt',
                redirect: '/tdfkxt/equip',
                name: '天敌防控系统',
                meta: { title: '天敌防控系统', icon: 'el' }
              },
              {
                path: '/wswpwxt',
                redirect: '/wswpwxt/equip',
                name: '微生物喷雾系统',
                meta: { title: '微生物喷雾系统', icon: 'el' }
              },
              {
                path: '/ssnyckxt',
                redirect: '/ssnyckxt/equip',
                name: '设施农业测控系统',
                meta: { title: '设施农业测控系统', icon: 'el' }
              },
            )
            console.log("val",val);
          }
          
        })
        accessedRoutes = asyncRoutes
      }else if(account == '三台县农业局植保站'){
        let arr = []
        let pathList = ['/alarmL','/alarmL/config','/home','/home/index','/neutralize_Config','/neutralizeConfig','/telemeter','/worms','/seedling_','/seedling','/lyrical_','/lyrical','/weather_','/weather',]
        asyncRoutes.forEach(val=>{
          if(val.path&&pathList.includes(val.path)){
            arr.push(val)
          }
        })
        arr.forEach(v=>{
          // 苗情
          if(v.path == '/seedling_'){
            v.children = v.children.filter(value=>{
              return value.path == '/seedling'
            })
          }
          // 墒情
          if(v.path == '/lyrical_'){
            v.children = v.children.filter(value=>{
              return value.path == '/lyrical'
            })
          }
          // 气象
          if(v.path == '/weather_'){
            v.children = v.children.filter(value=>{
              return value.path == '/weather'
            })
          }
        })
        accessedRoutes = arr
      }else
      
      if (roles.includes("admin")) {
        accessedRoutes = asyncRoutes || [];
      } else if (roles.includes("beadmin") || roles.includes("比昂内务部")) {
        accessedRoutes = [
          ...asyncRoutes,
          {
            path: "/sys_",
            component: Layout,
            meta: { title: "系统设置", icon: "setting" },
            children: [
              {
                path: "/sys",
                redirect: "/sys/role",
                name: "系统设置",
                meta: { title: "系统设置", icon: "setting" }
              }
            ]
          },
          {
            path: "/sys",
            component: () => import("@/layout"),
            redirect: "/sys/role",
            name: "系统设置",
            // meta: { title: '系统设置', icon: 'setting' },
            children: [
              {
                path: "role",
                name: "角色管理",
                component: () => import("@/views/sys/role"),
                meta: { title: "角色管理", icon: "table" }
              },
              {
                path: "dept",
                name: "部门管理",
                component: () => import("@/views/sys/dept"),
                meta: { title: "部门管理", icon: "tree" }
              },
              {
                path: "mem",
                name: "人员管理",
                component: () => import("@/views/sys/mem"),
                meta: { title: "人员管理", icon: "tree" }
              },
              {
                path: "auth",
                name: "权限管理",
                component: () => import("@/views/sys/auth"),
                meta: { title: "权限管理", icon: "tree" }
              },
              {
                path: "set",
                name: "系统设置",
                component: () => import("@/views/sys/set"),
                meta: { title: "系统设置", icon: "tree" }
              },
              {
                path: "app",
                name: "APP管理",
                component: () => import("@/views/sys/app"),
                meta: { title: "APP管理", icon: "tree" }
              }
            ]
          }
        ];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
