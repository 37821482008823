<template>
<!-- 左侧导航组件 -->
  <div class="menu-wrapper">
    <!--    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">-->
    <!--      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">-->
    <!--        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">-->
    <!--          <div class="img" :class="item.meta.icon" />-->
    <!--          <item :icon="item.meta.icon" :title="onlyOneChild.meta.title" />-->
    <!--        </el-menu-item>-->
    <!--      </app-link>-->
    <!--    </template>-->
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item  :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}">
          <div class="img" :class="onlyOneChild.meta.icon" />
          <item :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else-if="item.meta?true:false" v-show="item.name!='平台配置'" ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title" >
        <div class="img" :class="item.meta?item.meta.icon:''" />
        <item>{{item}}</item>
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>

  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  
created(){
},
  methods: {
    resolvePath(routePath) {
      // console.log(routePath)
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    hasOneShowingChild(children = [], parent) {
      // console.log("children",children);
      // console.log("parent",parent);
      
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)  //Temp集(当只有一个子节点显示时使用)
          this.onlyOneChild = item
          return true
        }
      })
      // When there is only one child router, the child router is displayed by default  // false的时候当只有一个子路由器时，默认情况也要显示子路由器(可以下拉)
      if (showingChildren.length === 1 && showingChildren[0].name === 'home') {
        // console.log("showingChildren",showingChildren);
        return true
      }else if(showingChildren.length===1&&showingChildren[0].meta&&showingChildren[0].meta.title === '环境友好型杀虫灯'){
        return true
      }else if(showingChildren.length === 1){
        return false
      }
      // Show parent if there are no child router to display  //如果没有子路由器显示，显示父路由器
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        
        return true
      }
      return false
    }
  }
}
</script>
<style>
    .el-submenu .img, .el-menu-item.submenu-title-noDropdown .img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 8px;
        background-size: cover !important;
    }

    .el-menu-item.submenu-title-noDropdown .img.home {
        background: url("../../../assets/navIcon/sy.png") no-repeat;
    }

    .el-submenu .img.monitor, .el-menu-item.submenu-title-noDropdown .img.monitor {
        background: url("../../../assets/navIcon/cqjk.png") no-repeat;
    }

    .el-submenu .img.sex, .el-menu-item.submenu-title-noDropdown .img.sex {
        background: url("../../../assets/navIcon/xycby.png") no-repeat;
    }

    .el-submenu .img.uv, .el-menu-item.submenu-title-noDropdown .img.uv {
        background: url("../../../assets/navIcon/wncby.png") no-repeat;
    }

    .el-submenu .img.lamp, .el-menu-item.submenu-title-noDropdown .img.lamp {
        background: url("../../../assets/navIcon/scd.png") no-repeat;
    }

    .el-submenu .img.miaoqing, .el-menu-item.submenu-title-noDropdown .img.miaoqing {
        background: url("../../../assets/navIcon/mqjk.png") no-repeat;
    }

    .el-submenu .img.lyrical, .el-menu-item.submenu-title-noDropdown .img.lyrical {
        background: url("../../../assets/navIcon/sqjk.png") no-repeat;
    }

    .el-submenu .img.weather, .el-menu-item.submenu-title-noDropdown .img.weather {
        background: url("../../../assets/navIcon/qxz.png") no-repeat;
    }

    .el-submenu .img.setting, .el-menu-item.submenu-title-noDropdown .img.setting {
        background: url("../../../assets/navIcon/xtsz.png") no-repeat;
    }
    .el-submenu .img.el, .el-menu-item.submenu-title-noDropdown .img.el {
        background: url("../../../assets/navIcon/dzxycby_cs@2x.png") no-repeat;
    }
    /* 打药系统 */
    .el-submenu .img.dyxt, .el-menu-item.submenu-title-noDropdown .img.dyxt {
        background: url("../../../assets/navIcon/dyxt.png") no-repeat;
    }
    /* 其他功能 */
    .el-submenu .img.qtgn, .el-menu-item.submenu-title-noDropdown .img.qtgn {
        background: url("../../../assets/navIcon/qtgn.png") no-repeat;
    }

    .router-link-active .el-menu-item.submenu-title-noDropdown .img.home, .submenu-title-noDropdown:hover .img.home {
        background: url("../../../assets/navIcon/sy_bs.png") no-repeat;
    }

    /* 环境友好型杀虫灯 */
    .el-submenu .img.qtgn, .el-menu-item.submenu-title-noDropdown .img.hj {
        background: url("../../../assets/navIcon/hj1.png") no-repeat;
    }
    /* 环境友好型杀虫灯 */
    #app .sidebar-container .el-submenu .el-menu-item:hover .img.dyxt,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.dyxt,
    .router-link-active .el-menu-item .img.hj,/* 激活导航显示icon效果 */
    .submenu-title-noDropdown:hover .img.hj {/* 鼠标移入显示icon效果 */
        background: url("../../../assets/navIcon/hj2.png") no-repeat;
    }
/* 打药系统 */
    #app .sidebar-container .el-submenu .el-menu-item:hover .img.dyxt,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.dyxt,
    .router-link-active .el-menu-item .img.dyxt,
    .submenu-title-noDropdown:hover .img.dyxt {
        background: url("../../../assets/navIcon/dyxt_bs.png") no-repeat;
    }
    /* 其他功能 */
    #app .sidebar-container .el-submenu .el-menu-item:hover .img.qtgn,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.qtgn,
    .router-link-active .el-menu-item .img.qtgn,
    .submenu-title-noDropdown:hover .img.qtgn {
        background: url("../../../assets/navIcon/qtgn_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.monitor,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.monitor,
    .router-link-active .el-menu-item .img.monitor,
    .submenu-title-noDropdown:hover .img.monitor {
        background: url("../../../assets/navIcon/cqcb_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.sex,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.sex,
    .router-link-active .el-menu-item .img.sex,
    .submenu-title-noDropdown:hover .img.sex {
        background: url("../../../assets/navIcon/xycb_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.uv,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.uv,
    .router-link-active .el-menu-item .img.uv, .submenu-title-noDropdown:hover .img.uv {
        background: url("../../../assets/navIcon/wncb_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.lamp,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.lamp,
    .router-link-active .el-menu-item .img.lamp, .submenu-title-noDropdown:hover .img.lamp {
        background: url("../../../assets/navIcon/scd_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.miaoqing,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.miaoqing,
    .router-link-active .el-menu-item .img.miaoqing, .submenu-title-noDropdown:hover .img.miaoqing {
        background: url("../../../assets/navIcon/mqcb_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.lyrical,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.lyrical,
    .router-link-active .el-menu-item .img.lyrical, .submenu-title-noDropdown:hover .img.lyrical {
        background: url("../../../assets/navIcon/trsq_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.weather,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.weather,
    .router-link-active .el-menu-item .img.weather, .submenu-title-noDropdown:hover .img.weather {
        background: url("../../../assets/navIcon/qxz_bs.png") no-repeat;
    }

    #app .sidebar-container .el-submenu .el-menu-item:hover .img.setting,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.setting,
    .router-link-active .el-menu-item .img.setting, .submenu-title-noDropdown:hover .img.setting {
        background: url("../../../assets/navIcon/sz_bs.png") no-repeat;
    }
    #app .sidebar-container .el-submenu .el-menu-item:hover .img.el,
    #app .sidebar-container .el-submenu .el-submenu__title:hover .img.el,
    .router-link-active .el-menu-item .img.el, .submenu-title-noDropdown:hover .img.el {
        background: url("../../../assets/navIcon/dzxycby_bs@2x.png") no-repeat;
    }
    /*  */
    .el-submenu__icon-arrow{
      position: relative;
      top: 0;
      right: 0;
      margin: 0;
      margin-left: 20px;
    }
</style>
