import { getDev, gotSwitch, gotConfig, gotDevices, addDev, delDev, modDev, getWarn, addWarn, modWarn, gotHistory } from '@/api/worms_alarm'
import { getInsects } from '@/api/worms'
function againCombie(temp) {
  let wcc = JSON.parse(temp)
  let arr = []
  for (let key in wcc) {
    arr.push({
      name: key,
      type: key,
      default: wcc[key]
    })
  }
  arr.forEach((item, index) => {
    let cache = item.default
    arr[index].default = []
    for (let key in cache) {
      arr[index].default.push({
        [key]: cache[key]
      })
    }
  })
  return arr
}
const state = {
  list: [],
  pageObj: {
    pageNum: 1,
    pageSize: 10
  },
  pageTotal: 10,
  foDefault: [],
  forecastCC: [],
  weatherCC: [],
  moistureCC: [],
  uDevices: [],
  uInsects: []
}

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list
  },
  SET_TOTAL: (state, pageTotal) => {
    state.pageTotal = pageTotal
  },
  SET_PAGE: (state, pageObj) => {
    state.pageObj = pageObj
  },
  SET_FCC: (state, FCC) => {
    state.forecastCC = FCC
  },
  SET_FO: (state, FO) => {
    state.foDefault = FO
  },
  SET_WCC: (state, WCC) => {
    state.weatherCC = WCC
  },
  SET_MCC: (state, MCC) => {
    state.moistureCC = MCC
  },
  SET_UDC: (state, UDC) => {
    state.uDevices = UDC
  },
  SET_ITS: (state, ITS) => {
    state.uInsects = ITS
  }
}

const actions = {
  /* 获得列表*/
  getList({ commit, state }, pageObj) {
    commit('SET_PAGE', pageObj)
    return new Promise((resolve, reject) => {
      getDev(pageObj).then(response => {
        const { data } = response
        if (data && data.records) {
          data.records.forEach((item, index) => {
          })
          commit('SET_LIST', data.records)
          // commit('SET_TOTAL', data.total)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* 开关*/
  gSwitch({ commit, state }, obj) {
    // commit('SET_PAGE', obj)
    return new Promise((resolve, reject) => {
      gotSwitch(obj).then(response => {
        const { data } = response
        if (data && data.records.length > 0) {
          data.records.forEach((item, index) => {
            if (item.status === 0) {
              data.records[index].checked = false
            } else {
              data.records[index].checked = true
            }
          })
          commit('SET_LIST', data.records)
          // commit('SET_TOTAL', data.total)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* 配置*/
  gConfig({ commit, state }, obj) {
    // commit('SET_PAGE', obj)
    return new Promise((resolve, reject) => {
      gotConfig(obj).then(response => {
        const { data } = response
        const temp = data.patternJson
        if (obj.warnType == 'forecast') {
          let fc = state.forecastCC
          fc.forEach(item => {
            item.default = JSON.parse(temp)
          })
          commit('SET_FO', JSON.parse(temp))
          commit('SET_FCC', fc)
        } else if (obj.warnType == 'weather') {
          commit('SET_WCC', againCombie(temp))
        } else if (obj.warnType == 'moisture') {
          commit('SET_MCC', againCombie(temp))
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* 获取设备*/
  gDevices({ commit, state }, obj) {
    // commit('SET_PAGE', obj)
    return new Promise((resolve, reject) => {
      gotDevices(obj).then(response => {
        const { data } = response
        for (let index = 0; index < data.length; index++) {
          if (data[index].name && data[index] !== '') {
            continue
          } else {
            if (data[index].alias && data[index].alias !== '') {
              data[index].name = data[index].alias
              continue
            } else {
              if (data[index].alias_name && data[index].alias_name !== '') {
                data[index].name = data[index].alias_name
                continue
              } else {
                data[index].name = data[index].imei
              }
            }
          }
        }
        commit('SET_UDC', data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* 获取虫子*/
  gInsects({ commit, state }, obj) {
    // commit('SET_PAGE', obj)
    return new Promise((resolve, reject) => {
      getInsects(obj).then(response => {
        const { data } = response
        data.forEach((item, index) => {
          data[index].checked = false
        })
        commit('SET_ITS', data)
        console.log(state.uInsects)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* 增加配置单*/
  gAddEquip({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      if (obj.id) {
        modDev(obj).then(response => {
          const { data } = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      } else {
        addDev(obj).then(response => {
          const { data } = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  /* 删除配置*/
  gDel({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      delDev(obj).then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* get配置*/
  gWarn({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      getWarn(obj).then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* add配置*/
  gWarnAdd({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      addWarn(obj).then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* modiy配置*/
  gmodWarn({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      modWarn(obj).then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  /* modi记录*/
  gRecord({ commit, state }, obj) {
    return new Promise((resolve, reject) => {
      gotHistory(obj).then(response => {
        const { data } = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

