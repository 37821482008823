import axios from 'axios'
// import axios from '@/utils/axios'
import {MessageBox, Message} from 'element-ui'

// create an axios instance
const service = axios.create({
    baseURL: 'https://yun.bigdata5s.com/api',  // 绿色云农
    // baseURL:'http://192.168.0.133:8769/v1/',
    withCredentials: false, // send cookies when cross-domain requests  跨域请求时发送cookie
    timeout: 50000,// request timeout  请求超时
})

// request拦截器
service.interceptors.request.use(config => {
    const screenToken = localStorage.getItem('v2ScreenToken')
    if (screenToken) {
        config.headers['Authorization'] = screenToken;
        config.headers['issuer'] = 'enroll';
        config.headers['cvn-user-id'] = localStorage.getItem('v2UserId');
    }
    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// response interceptor  响应拦截器
service.interceptors.response.use(
    response => {
        console.log('response', response)
        const {
            status,
            data: {status: codeOther, code, msg},
        } = response;
        if (status == 200 && (code == 200 || codeOther == 200)) {
            return response.data;
        } else {
            // 响应数据为二进制流处理(Excel导出)
            if (
                response.data instanceof ArrayBuffer ||
                response.data instanceof Blob
            ) {
                return response;
            }
            return Promise.reject(response.data);
        }
    },
    error => {
        // console.log(error)
        // console.log('err' + error) // for debug
        if (error.response.status == 401 || error.response.status == 403) {
            ElMessageBox.confirm('当前页面已失效，请重新登录', '警告', {
                confirmButtonText: 'OK',
                type: 'warning',
            }).then(() => {
                localStorage.clear();
                window.location.href = '/';
            });
        }
        if (error.response.data) {
            console.log('error 2');
            return Promise.reject(error.response.data);
        }
        console.log('error 3');
        return Promise.reject(error)
    }
)
export default service
