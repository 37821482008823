import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
/* 报警系统*/
import alarmL from '@/layout/alarm'
// 管理中心
import manageCenter from '@/layout/manageCenter'
import manageCenter2 from '@/layout/manageCenter2'


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements  没有权限要求的基本页面
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/Redirect',
    component: () => import('@/views/Redirect'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/login'
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  
  // 左侧导航路由
  // {
  //   path: '/home',
  //   component: Layout,
  //   meta: { title: '首页', icon: 'home' },
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'home',
  //       component: () => import('@/views/home/index'),
  //       meta: { title: '首页', icon: 'home' }
  //     }
  //   ]
  // },
  // 平台配置
  {
    path:'/neutralize_Config',
    component:Layout,
    meta:{title:'平台配置'},
    name:'平台配置',
    children:[
      {
        path:'/neutralizeConfig',
        redirect:'/neutralizeConfig/index',
        name:'平台配置',
        meta: { title: '平台配置'},
      },
    ]
  },
  {
    path:'/neutralizeConfig',
    component:Layout,
    redirect:'/neutralizeConfig/index',
    name:'平台配置',
    children:[
      {
        path:'index',
        name:'平台配置',
        component:()=>import('@/views/neutralizeConfig/index'),
      }
    ]
  },
  
  // 报警系统
  {
    path: '/alarmL',
    component: alarmL,
    // meta: { title: '报警系统', icon: 'weather' },
    redirect: '/alarmL/config',
    children: [
      {
        path: 'config',
        name: '报警配置',
        component: () => import('@/views/alarm/config')
      },
      {
        path: 'mode',
        name: '报警方式配置',
        component: () => import('@/views/alarm/mode')
      },
      {
        path: 'record',
        name: '历史报警记录',
        component: () => import('@/views/alarm/record')
      }
    ],
   
  },
  // 管理中心
  {
    path: '/manageCenter',
    component: manageCenter,
    meta: { title: '管理中心', icon: 'weather' },
    redirect: '/manageCenter/peopleManage',
    children: [
      {
        path: 'peopleManage',
        // redirect:'/peopleManage/index',
        name: '人员管理',
		    meta:{title:'人员管理'},
        component: () => import('@/views/manageCenter/peopleManage')
      },
	    {
        path: 'taskAnnouncementManage',
        // redirect:'/taskAnnouncementManage/index',
        name: '任务公告管理',
		    meta:{title:'任务公告管理'},
        component: () => import('@/views/manageCenter/taskAnnouncementManage')
      },
      {
        path: 'productionProcessManagement',
        // redirect:'/manageCenter/productionProcessManagement/plantingInfo',
        name: '生产过程管理',
		    meta:{title:'生产过程管理'},
        component:manageCenter2,
        children:[
          {
            path:'fieldInfo',
            name:'田地信息',
            meta:{title:'田地信息'},
            // component:manageCenter2,
            component: () => import('@/views/manageCenter/productionProcessManagement/fieldInfo/tdInfo')
            // children:[
            //   {
            //     path:'tdInfo',
            //     name:'田地信息',
            //     meta:{title:'田地信息'},
                
            //   },
            //   {
            //     path:'add',
            //     name:'添加',
            //     meta:{title:'添加'},
            //     component: () => import('@/views/manageCenter/productionProcessManagement/fieldInfo/add')
            //   }
            // ]
          },
          {
            path:'plantingInfo',
            name:'种植信息',
            meta:{title:'种植信息'},
            component: () => import('@/views/manageCenter/productionProcessManagement/plantingInfo')
          },
          {
            path:'fertilizationRecorded',
            name:'施肥记录',
            meta:{title:'施肥记录'},
            component: () => import('@/views/manageCenter/productionProcessManagement/fertilizationRecorded')
          },
          {
            path:'preventionAndControlRecords',
            name:'防治记录',
            meta:{title:'防治记录'},
            component: () => import('@/views/manageCenter/productionProcessManagement/preventionAndControlRecords')
          },
          {
            path:'harvestRecords',
            name:'采摘记录',
            meta:{title:'采摘记录'},
            component: () => import('@/views/manageCenter/productionProcessManagement/harvestRecords')
          },
          {
            path:'productionValueManage',
            name:'产值管理',
            meta:{title:'产值管理'},
            component: () => import('@/views/manageCenter/productionProcessManagement/productionValueManage')
          },
        ]
      },
      {
        path: 'finishedProductQualityManage',
        // redirect:'/manageCenter/productionProcessManagement/plantingInfo',
        name: '成品质量管理',
		    meta:{title:'成品质量管理'},
        component:manageCenter2,
        children:[
          {
            path:'qualityInformation',
            name:'质量信息',
            meta:{title:'质量信息'},
            component: () => import('@/views/manageCenter/finishedProductQualityManage/qualityInformation')
          },
          {
            path:'productTesting',
            name:'产品检测',
            meta:{title:'产品检测'},
            component: () => import('@/views/manageCenter/finishedProductQualityManage/productTesting')
          },
          {
            path:'ProductInventoryAndSales',
            name:'产品库存/销售',
            meta:{title:'产品库存/销售'},
            component: () => import('@/views/manageCenter/finishedProductQualityManage/ProductInventoryAndSales')
          },
          {
            path:'returnManage',
            name:'returnManage',
            meta:{title:'退货管理'},
            component: () => import('@/views/manageCenter/finishedProductQualityManage/returnManage')
          },
          {
            path:'inventoryManage',
            name:'inventoryManage',
            meta:{title:'库存管理'},
            component: () => import('@/views/manageCenter/finishedProductQualityManage/inventoryManage')
          },
        ]
      },
      {
        path: 'infoAndDataManage',
        // redirect:'/manageCenter/productionProcessManagement/plantingInfo',
        name: '信息数据管理',
		meta:{title:'信息数据管理'},
        component:manageCenter2,
        children:[
          {
            path:'insectInfoManage',
            name:'昆虫信息管理',
            meta:{title:'昆虫信息管理'},
            component: () => import('@/views/manageCenter/infoAndDataManage/insectInfoManage')
          },
          {
            path:'cashCropManage',
            name:'经济作物管理',
            meta:{title:'经济作物管理'},
            component: () => import('@/views/manageCenter/infoAndDataManage/cashCropManage')
          },
          {
            path:'equipLocationManage',
            name:'设备位置管理',
            meta:{title:'设备位置管理'},
            component: () => import('@/views/manageCenter/infoAndDataManage/equipLocationManage')
          },
        ]
      }
    ],
  },



  // 404 page must be placed at the end !!!   //404页面必须放置在末尾
  // { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
// router.addRoutes(asyncRoutes);
export default router
