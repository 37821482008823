// 接口需要拼接时 将对象参数直接处理函数 pageNum=8&pageSize=1&type=1
export function serialJsonToForm(obj){ 
	var aryForm=[];
	for(var key in obj){
	  if(obj[key]){
	  aryForm.push(key+ "=" + obj[key]);
	  }
	}
	if(aryForm.length==0) return "";
	return aryForm.join("&");
  }