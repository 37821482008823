import request from '@/utils/request'


//   随机数
function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};
export let imgDeviceId = generateUUID();

// 登录接口
export function login(params) {
    return request({
        url: '/auth-server/authentication/form',
        method: 'POST',
        params,
        headers: {
            //   'Content-Type':'application/x-www-form-urlencoded',
            Authorization: 'Basic YmV5b25kMjpzZWNyZXQy',
            deviceId: imgDeviceId,
        },
    })
}


// https://wechat.scyphr.com/v1/fourmood-web/big/big-info/module/findByBigKey?bigKey=V2-OWNER-CONFIG

export function checkUser(params) {
    return request({
        url: '/fourmood-web/big/big-info/module/findByBigKey?bigKey=V2-OWNER-CONFIG',
        method: 'get',
    })
}
