import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import $ from '@/utils/jquery-1.8.3.min.js'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router/index3'
import BaiduMap from './components/vue-baidu-map/components'
// import BaiduMap from 'vue-baidu-map'
// 将对象参数直接处理函数 pageNum=8&pageSize=1&type=1
import {    serialJsonToForm} from "@/utils/tool"

import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)

import Swiper from 'swiper'
import './styles/swiper.min.css'

import '@/icons' // icon
import '@/permission' // permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
import { mockXHR } from '../mock'

if (process.env.NODE_ENV !== 'production') {
  // mockXHR()
}

// set ElementUI lang to EN
Vue.use(ElementUI, { locale })

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

//全局注册百度地图
Vue.use(BaiduMap, {
  ak: 'E3tex8pZYbMMry7fSd9tTSB8rPRy1FZy'
})

Vue.config.productionTip = false

Vue.prototype.$swiper = Swiper // 全局注册Swiper
Vue.prototype.serialJsonToForm = serialJsonToForm
// 中央事件总线bus
Vue.prototype.$bus = new Vue()


// const _authRole = sessionStorage.getItem('userInfo')
// const authRole = _authRole ? JSON.parse(sessionStorage.getItem('userInfo')) : null

// if (authRole && (authRole.account === 'beadmin' || authRole.account === '比昂内务部' || authRole.account === 'ceshi')) {
//   console.log("_authRole",_authRole);
// console.log("authRole",authRole);
//   Vue.prototype.$authStatus = true
// } else {
//   console.log("_authRole",_authRole);
// console.log("authRole",authRole);
//   Vue.prototype.$authStatus = false
// }
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
