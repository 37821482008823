// import requestMock from '@/utils/requestMock'
import request from '@/utils/request'
const userBase = 'service-user'

// /*获得部门列表*/
export function getDept(params) {
  return request({
    url: userBase + '/depart/query',
    method: 'POST',
    params
  })
}
// /*新增部门，子部门*/
export function addDept(params) {
  return request({
    url: userBase + '/depart/add',
    method: 'POST',
    data: params
  })
}
// /*新增部门，删除部门*/
export function delDept(params) {
  return request({
    url: userBase + '/depart/del',
    method: 'POST',
    params
  })
}
// /*获得权限列表*/
export function getAuth(params) {
  return request({
    url: userBase + '/perm/query',
    method: 'POST',
    params
  })
}
// /*新增权限，子权限*/
export function addAuth(params) {
  return request({
    url: userBase + '/perm/add',
    method: 'POST',
    data: params
  })
}
// /*获得角色列表*/
export function getRole(params) {
  return request({
    url: userBase + '/role/query',
    method: 'POST',
    params
  })
}
// /*新增角色*/
export function addRole(params) {
  return request({
    url: userBase + '/role/add',
    method: 'POST',
    data: params
  })
}
// /*删除角色*/
export function delRole(params) {
  return request({
    url: userBase + '/role/del',
    method: 'POST',
    params
  })
}
// /*获得用户列表*/
export function getMem(params) {
  return request({
    url: userBase + '/user/query',
    method: 'GET',
    params
  })
}
// /*新增角色*/
export function addUser(params) {
  return request({
    url: userBase + '/user/add',
    method: 'POST',
    data: params
  })
}
// /*删除角色*/
export function delMem(params) {
  return request({
    url: userBase + '/user/del',
    method: 'POST',
    params
  })
}
// /*修改账号密码*/
export function modifyPass(params) {
  return request({
    url: userBase + '/user/modifyPwd',
    method: 'POST',
    params
  })
}
// /* 换取农机token*/
export function changeToken(params) {
  return request({
    url: userBase + '/authorize/userInfo',
    method: 'GET'
  })
}

// 修改平台配置
export function editPlatConfig(params) {
  return request({
    url: userBase + '/companyInfo/saveUpdate',
    method: 'POST',
    data:params
  })
}


/* 获得app列表*/
export function getAppList(params) {
  return request({
    url: userBase + '/app/query',
    method: 'get',
    params
  })
}
/* 获得app列表*/
export function addApp(params) {
  return request({
    url: userBase + '/app/add',
    method: 'post',
    data: params
  })
}

// 获取用户菜单
export function getMenuList(params) {
    return request({
      url: userBase + '/menu/userMenu',
      method: 'get',
      params
    })
  }

  // 修改用户菜单
export function updateMenu(params) {
    return request({
      url: userBase + '/menu/updateUserMenu',
      method: 'post',
      data:params
    })
  }