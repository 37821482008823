import { getMG, editMG } from '@/api/worms_iot'
import moment from 'moment'
const state = {
  iotFormSetName: { iccid: '', edit: false },
  fuch: {
    cardStatus: '暂无',
    effectiveDate: '—',
    expiriedDate: '—',
    totalAmount: '0',
    useAmount: '0',
    remainAmount: '0'
  },
}

const mutations = {
  SET_FUCH: (state, FUCH) => {
    state.fuch = FUCH
  },
}

const actions = {
  /* 获得列表*/
  _getMG({ commit, state }, para) {
    commit('SET_FUCH',{
      cardStatus: '暂无',
      effectiveDate: '—',
      expiriedDate: '—',
      totalAmount: '0',
      useAmount: '0',
      remainAmount: '0'
    })
    return new Promise((resolve, reject) => {
      getMG(para).then(response => {
        console.log("response 获得列表",response);
        if(response.code == 200&&response.data){
          const { data } = response
          
          commit('SET_FUCH', {
            cardStatus: data.mealExplain ? data.mealExplain : '暂无',
            effectiveDate: data.effectiveDate ? moment(data.effectiveDate).format('YYYY-MM-DD')  : '—',
            expiriedDate: data.expiriedDate ? moment(data.expiriedDate).format('YYYY-MM-DD') : '—',
            totalAmount: data.totalAmount ? data.totalAmount : '0',
            useAmount: data.useAmount ? data.useAmount : '0',
            remainAmount: data.remainAmount ? data.remainAmount : '0',
            meal:data.meal?data.meal:'暂无'
          }
          )
          resolve(data)
        }else{
          resolve()
        }
      })
    })
  },
  /* 修改信息*/
  _editMG({ commit, state }, para) {
    return new Promise((resolve, reject) => {
      console.log("para 修改物联卡iccid参数",para);
      editMG(para).then(response => {
        console.log("response 修改物联卡iccid",response);
        
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
