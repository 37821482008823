import router, { resetRouter } from './router/index3'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar  页面跳转是出现在浏览器顶部的进度条
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { changeToken } from '@/api/sys'  //换取农机token
//      登录      
import {login,imgDeviceId} from "@/api/login.js"
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/login.html', '/Redirect'] // no redirect whitelist  没有重定向白名单

router.beforeEach(async(to, from, next) => {
  
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in 确定用户是否已经登录
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page如果已登录，则重定向到主页
      // next({ path: '/login' })
      next()
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo   确定用户是否通过getInfo获得了他的权限角色
        // if(localStorage.getItem("cont") !== 1){
        //     router.addRoutes(asyncRoutes);
        //     localStorage.setItem("cont",1);
        //     next({ ...to, replace: true })
        // }else{
        //     next()
        // }
      let hasRoles = store.getters.roles && store.getters.roles.length > 0
      // hasRoles = true
      if (hasRoles) {
        if(to.name == '人员管理'||to.name == '任务公共管理'||to.name == '种植信息'){
          
          const accessRoutes = await store.dispatch('manageCenter/generateRoutes')
          // dynamically add accessible routes  动态添加可访问路由
          router.addRoutes(accessRoutes)
          next()
        }else{
          // console.log("to",to); 
          if(!(to.path == '/pzykxt/equip'||to.path == '/tdfkxt/equip'||to.path == '/wswpwxt/equip'||to.path == '/ssnyckxt/equip')){
            next()
          }else{
            Message.error('暂无系统权限！')
          }

        }
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']  角色必须是对象数组 
          const { roles } = store.dispatch('user/getInfo')
          const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
          if (userInfo.account !== 'beadmin') {
            const userIdToken = await changeToken()
            if (userIdToken.code == 200) {
              if(userIdToken.data.config){
                // 中性化平台配置
                sessionStorage.setItem('config',JSON.stringify(userIdToken.data.config))
              }
              if (userIdToken.data.userId == sessionStorage.getItem('userId')) {
                sessionStorage.setItem('userId', userIdToken.data.userId)
              } else {
                sessionStorage.setItem('userId', userIdToken.data.userId)
                // setTimeout(() => {
                //   window.location.reload()
                // })
              }
            }
          }
          // generate accessible routes map based on roles  根据角色生成可访问路由图
          const accessRoutes = await store.dispatch('permission/generateRoutes', [(userInfo && (userInfo.account == '比昂内务部' || userInfo.account == 'beadmin')) ? userInfo.account : 'admin'])
          // dynamically add accessible routes  动态添加可访问路由
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete    hack方法，以确保addRoutes是完整的
          // set the replace: true, so the navigation will not leave a history record    设置replace: true，这样导航将不会留下历史记录
          
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login    删除令牌，进入登录页面重新登录
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          // window.location.href = '/login.html'
          NProgress.done()
        }
      }
    }
  } else {


    if(to.path == "/login"){
      next()
    }
    
    let href = window.location.href
    if(href.includes("?")){
      let para = href.substring(href.indexOf("?")+1,href.indexOf("#"))
      if(para){
        let arr = para.split('&')
        let obj = {}
        arr.forEach(val=>{
          val.split('=')
          obj[val.split('=')[0]] = val.split('=')[1]
        })

        // http://192.168.0.175:4001/?type=2&user=hejiang001#/home/index  模板
        
        if(!sessionStorage.getItem("userPara")){
          var user1 = obj.user == 'hejiang001';
          var user4 = obj.user == 'hejiang002';
          var user2 = obj.user == 'jiangeH';
          var user3 = obj.user == 'aiot001';
          var user5 = obj.user == 'cdbeyond';
          var user6 = obj.user == 'pujiang01'
          if(user1 || user2||user3||user4||user5||user6){
  
            let me;
            let pass;
  
            if(user1){
              me = '雅安一凡信息技术服务有限公司';
              pass = '888888';
          
            }
            if(user4){
              me = '四川数字星辰科技有限公司(合江县）';
              pass = '888888';
            }
  
            if(user2){
              me = '剑阁县农业农村局';
              pass = '888888';
            }
            if(user3){
              me = '四川艾欧特智能科技有限公司';
              pass = '888888';
            }
            if(user5){
              me = 'cdbeyond'
              pass = 'Aa123456'
            }
            if(user6){
              me = '蒲江县智慧农业数字化平台'
              pass = '888888'
            }
  
             // 直接登录
             let params = {
              username: me,
              password: pass,
              nocode: 100,
              imageCode: 111,
            };
  
            login(params).then(res=>{
              if(res.code == 200){
                sessionStorage.setItem("access_token",res.data.access_token)
                sessionStorage.setItem("userInfo",JSON.stringify({account:params.me}))
                sessionStorage.setItem("userPara",obj.user)
      
                const _authRole = sessionStorage.getItem('userInfo')
                const authRole = _authRole ? JSON.parse(_authRole) : null
      
                if (authRole && (authRole.account === 'beadmin' || authRole.account === '比昂内务部' || authRole.account === 'ceshi')) {
                  sessionStorage.setItem("$authStatus",JSON.stringify({isShow:true}))
                } else {
                  sessionStorage.setItem("$authStatus",JSON.stringify({isShow:false}))
                }
                next({path:'/home/index'})
              }
            })
          }
        }
        
      }
    }else{
      /* has no token*/
      if (whiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly    在免费登录白名单中，直接进入
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.   其他没有访问权限的页面被重定向到登录页面。
        // next(`/login?redirect=${to.path}`)
        next({ path: '/' })
        // window.location.href = '/login'
        NProgress.done()
      }
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
