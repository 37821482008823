import request from "@/utils/request-v2";

export function loginApi(data) {
  return request({
    url: "/user/passport/login",
    method: "post",
    data: data
  });
}

export function singlePointLoginApi(userId) {
  return request({
    url: "/user/passport/single/point/login",
    method: "get",
    params: { userId }
  });
}

/**
 * 通过图片ID获取昆虫识别记录
 * @param id
 */
export function getInsectRecordByPhotoIdApi(id) {
  return request({
    url: `/product/insect/getInsectRecordByPhotoId`,
    method: "get",
    params: {
      id
    }
  });
}

/**
 * // 分页获取昆虫信息管理
 * @param pageNum
 * @param pageSize
 */
export function postInsectInfoPageApi(data) {
  return request({
    url: `/product/insect/getInsectInfoPage`,
    method: "post",
    data
  });
}

/**
 * 添加昆虫信息管理
 */
export function saveInsectInfoApi(data) {
  return request({
    url: `/product/insect/saveInsectInfo`,
    method: "post",
    data
  });
}

/**
 * 修改昆虫信息管理
 */
export function editInsectInfoApi(data) {
  return request({
    url: `/product/insect/editInsectInfo`,
    method: "post",
    data
  });
}

/**
 * 删除昆虫信息管理
 */
export function deleteInsectListApi(id) {
  return request({
    url: `/product/insect/deleteInsectInfo`,
    method: "get",
    params: {
      id
    }
  });
}

/**
 * 启用昆虫信息
 */
export function enableInsectInfoApi(id) {
  return request({
    url: `/product/insect/startInsect`,
    method: "get",
    params: {
      id
    }
  });
}

/**
 * 停用昆虫信息
 */
export function disableInsectInfoApi(id) {
  return request({
    url: `/product/insect/stopInsect`,
    method: "get",
    params: {
      id
    }
  });
}

/**
 * 添加昆虫识别记录
 */
export function editInsectRecordApi(data) {
  return request({
    url: `/product/insect/editInsectRecord`,
    method: "post",
    data
  });
}

/**
 * 修改昆虫识别记录
 */
export function saveInsectRecordApi(data) {
  return request({
    url: `/product/insect/saveInsectRecord`,
    method: "post",
    data
  });
}

// 删除昆虫识别记录
export function deleteInsectInfoApi(id) {
  return request({
    url: `/product/insect/deleteInsectRecord`,
    method: "get",
    params: {
      id
    }
  });
}

export function getDevicePicPageDataApi(data) {
  return request({
    url: "/product/productDevice/getDevicePicPageData",
    method: "post",
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data
  });
}

/**
 * 获取指定ID设备最新的全部属性
 * @param deviceId
 * @param startTime
 * @param endTime
 */
export function getPropertiesLatestApi(deviceId, startTime, endTime) {
  return request({
    url: `/product/productDevice/${deviceId}/properties/latest`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    params: {
      startTime,
      endTime,
      pageSize: 1
    }
  });
}

/**
 * 获取设备历史数据
 * @param data
 */
export function postDeviceHistoryDataApi(data) {
  return request({
    url: "/product/productDevice/getDeviceHistoryData",
    method: "post",
    data: data
  });
}

// postDownInsectDataApi
/**
 * 获取设备实时最新数据
 * @param data
 */
export function postDownDeviceHistoryDataApi(data) {
  return request({
    url: "/product/productDevice/downDeviceHistoryData",
    method: "post",
    responseType: "blob",
    data: data
  });
}
