import {login, logout} from '@/api/user'
import {getToken, setToken, removeToken} from '@/utils/auth'
import {resetRouter} from '@/router'
import {loginApi} from '@/api/v2/index'

const _needToV2 = localStorage.getItem('needToV2') || '[]';
const needToV2 = JSON.parse(_needToV2) || []

const state = {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    needToV2: needToV2
}


const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    DEL_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_NEEDTOV2(state, needToV2) {
        localStorage.setItem('needToV2', JSON.stringify(needToV2))
        state.needToV2 = needToV2;
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        // const { username, password } = userInfo
        // const { data } = response
        commit('SET_TOKEN', 'YmV5b25kMjpzZWNyZXQy')
        setToken('YmV5b25kMjpzZWNyZXQy')
        // return new Promise((resolve, reject) => {
        //   login({ username: username.trim(), password: password }).then(response => {
        //
        //     resolve()
        //   }).catch(error => {
        //     reject(error)
        //   })
        // })
    },

    // get user info
    getInfo({commit, state}) {
        commit('SET_ROLES', ['admin'])
        commit('SET_NAME', 'Super Admin')
        commit('SET_AVATAR', '')
        // return new Promise((resolve, reject) => {
        //   getInfo(state.token).then(response => {
        //     const { data } = response;
        //
        //     if (!data) {
        //       reject('Verification failed, please Login again.')
        //     }
        //
        //     const { roles, name, avatar } = data;
        //     console.log(data)
        //
        //     // roles must be a non-empty array
        //     if (!roles || roles.length <= 0) {
        //       reject('getInfo: roles must be a non-null array!')
        //     }
        //
        //     commit('SET_ROLES', roles)
        //     commit('SET_NAME', name)
        //     commit('SET_AVATAR', avatar)
        //     resolve(data)
        //   }).catch(error => {
        //     reject(error)
        //   })
        // })
    },

    // user logout
    logout({commit, state}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                removeToken()
                resetRouter()
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

