<template>
  <div :class="{'has-logo':showLogo}">
    <!-- <logo style="flex:0 0 277px" v-if="showLogo" :collapse="isCollapse" /> -->
    
    <!-- <div class="title" v-if="!isCollapse">管理中心</div> -->
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
      <!-- <div> -->
        <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
        class="elmenu"
      >
        <sidebar-item unique-opened v-for="route in manageCenter"  :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
      <!-- </div> -->
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
// 左侧导航组件
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  
  computed: {
    ...mapGetters([
      'manageCenter',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      // 如果设置路径，侧边栏将突出显示您设置的路径
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  created(){
  },
  methods:{
    
  }
}
</script>
<style lang="scss" scoped>
.elmenu{
  flex: 1;
  overflow-y: scroll;
}
/* 滚动条样式 */
::-webkit-scrollbar{
    width: 0px;
    background-color: cornflowerblue;
}
/* 滚动滑块样式 */
::-webkit-scrollbar-thumb{
    background-color: skyblue;
    height: 8px;
    border-radius: 10px;
}

.title{
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 30px;
}
</style>
