const getters = {
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    roles: state => state.user.roles,
    permission_routes: state => state.permission.routes,
    manageCenter: state => state.manageCenter.routes,
    list: state => state.alarm.list,
    needToV2: state => state.user.needToV2 || [],
}
export default getters
