<template>
  <div id="app">
    <router-view />
    
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">

// 处理高空测报灯设备列表页的设备修改的查看位置 嵌套dialog 的.el-dialog__body自带的padding
  .innerDialog{
    .el-dialog{
        .el-dialog__body{
          padding: 0 ;
        }
    }
    
  }

// 远程信息化虫情测报系统 设备列表 增值服务 环境友好型杀虫灯  选择项目里嵌套Dialog 样式
  .innerDialog2{
    .el-dialog{
        .el-dialog__body{
          display: flex;
          flex-direction: column;
          .searchEquip{
            display: flex;
            justify-content: flex-start;
            position: relative;
            width: 250px;
            height: 30px;
            .el-input{
              width: 250px;
              height: 30px;
              .el-input__inner {
                height: 30px;
                line-height: 30px;
              }
            }
            img{
              width: 13px;
              height: 13px;
              position: absolute;
              right: 11px;
              top: 9px;
            }
          }
          button{
            align-self: center;
          }

            .el-checkbox{
              height: 30px;
              line-height: 30px;
              .el-checkbox__label{
                span{
                  padding-left: 20px;
                }
              }
              .el-checkbox__input.is-checked+.el-checkbox__label{
                color: #67C23A !important;
              }
              .el-checkbox__input.is-checked .el-checkbox__inner{
                border-color: #67C23A !important;
                background: #67C23A !important;
              }
            }
            .el-table{
                margin: 20px 0;
              td{
                padding: 8px 0;
              .cell{
                text-align: center;
              }
            }
             td, th{
              .cell{
                text-align: center;
              }
            }
            }
        }
    }
    
  }
  // 远程信息化虫情测报系统 设备列表 增值服务 环境友好型杀虫灯 选择项目里嵌套Dialog 单选框 样式
   .innerDialog3{
     
    .el-radio__input.is-checked+.el-radio__label {
      color: #67C23A !important;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #67C23A !important;
      background: #67C23A !important;
    }
    .el-dialog__body{
      display: flex;
      flex-direction: column;
      align-items: center;
      >div{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .el-radio{
              width: 110px;
              margin: 5px 11px;
        }
      }
      .el-button{
        width: 126px;
        margin-top: 15px;
      }
      
    }
    
  }
  // 远程信息化虫情测报系统 设备列表 增值服务 虫情识别 验证规则提示信息位置
  .dialog_common {
   .recognition{
       .el-form-item__error{
          top: 101% !important;
        }
   }
 }
</style>
