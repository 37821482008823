import request from '@/utils/request'
const userBase = 'fourmood-web'
// import axios from 'axios'

// /*获得信息*/
export function getMG(params) {
  return request({
    url: userBase + '/mobileIot/getCardInfo',
    method: 'POST',
    params
  })
}
// /*修改信息*/
export function editMG(params) {
  return request({
    url: userBase + '/mobileIot/updateCardInfo',
    method: 'POST',
    params
  })
}
