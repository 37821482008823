import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";
/* 报警系统*/
import alarmL from "@/layout/alarm";
import manageCenter from '@/layout/manageCenter'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 roles: ['admin','editor']    control the page roles (you can set multiple roles)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements  没有权限要求的基本页面
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },

  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true
  },
  {
    path: "/Redirect",
    component: () => import("@/views/Redirect"),
    hidden: true
  },
  {
    path: "/",
    component: Layout,
    redirect: "/login"
  }
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  // 左侧导航路由
  {
    path: "/home",
    component: Layout,
    meta: {title: "首页", icon: "home"},
    children: [
      {
        path: "index",
        name: "home",
        component: () => import("@/views/home/index"),
        meta: {title: "首页", icon: "home"}
      }
    ]
  },

  // 平台配置
  {
    path: "/neutralize_Config",
    component: Layout,
    meta: {title: "平台配置"},
    name: "平台配置",
    children: [
      {
        path: "/neutralizeConfig",
        redirect: "/neutralizeConfig/index",
        name: "平台配置",
        meta: {title: "平台配置"}
      }
    ]
  },
  {
    path: "/neutralizeConfig",
    component: Layout,
    redirect: "/neutralizeConfig/index",
    name: "平台配置",
    children: [
      {
        path: "index",
        name: "平台配置",
        component: () => import("@/views/neutralizeConfig/index")
      }
    ]
  },

  {
    path: "/friendly",
    component: Layout,
    redirect: "/friendly/equip",
    name: "环境友好型杀虫灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/environment_friendly/index")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/environment_friendly/map")
      },
      {
        path: "particulars",
        name: "详情页",
        hidden: true,
        component: () => import("@/views/environment_friendly/particulars")
      },
      {
        path: "editConfig",
        name: "修改配置",
        hidden: true,
        component: () => import("@/views/environment_friendly/editConfig")
      }
    ]
  },

  // 测报系统
  {
    path: "/telemeter",
    component: Layout,
    meta: {title: "虫情监测", icon: "monitor"},
    children: [
      {
        path: "/worms",
        redirect: "/worms/equip",
        name: "远程信息化虫情测报系统",
        meta: {title: "远程信息化虫情测报系统"}
      },
      {
        path: "/sexTelegraph",
        redirect: "/sexTelegraph/equip",
        name: "远程昆虫性诱测报仪",
        meta: {title: "远程昆虫性诱测报仪"}
      },
      {
        path: "/high",
        redirect: "/high/equip",
        name: "远程高空虫情测报系统",
        meta: {title: "远程高空虫情测报系统"}
      },
      {
        // path: '/el_',
        // component: Layout,
        // meta: { title: '远程电子性诱测报仪', icon: 'el' },
        // children: [
        //   {
        //     path: '/el',
        //     redirect: '/el/equip',
        //     name: '远程电子性诱测报仪',
        //     meta: { title: '远程电子性诱测报仪', icon: 'el' }
        //   }
        // ]

        path: "/el",
        redirect: "/el/equip",
        name: "远程电子性诱测报仪",
        meta: {title: "远程电子性诱测报仪"}
      },
      {
        path: "/worms_dfs",
        redirect: "/worms_dfs/equip",
        name: "远程智慧虫情测报仪",
        meta: {title: "远程智慧虫情测报仪"}
      },
      // 稻水象甲监测仪
      {
        path: "/dsxj",
        redirect: "/dsxj/equip",
        name: "稻水象甲监测仪",
        meta: {title: "稻水象甲监测仪"}
      },
      {
        path: "/yqc",
        redirect: "/yqc/equip",
        name: "烟甲虫监测仪",
        meta: {title: "烟甲虫监测仪"}
      }
    ]
  },

  // 病情监测
  {
    path: "/spore_",
    component: Layout,
    meta: {title: "病情监测", icon: "el"},
    children: [
      {
        path: "/spore",
        redirect: "/spore/equip",
        name: "孢子捕捉仪",
        meta: {title: "孢子捕捉仪"}
      }
    ]
  },
  // 苗情监测
  {
    path: "/seedling_",
    component: Layout,
    meta: {title: "苗情监测", icon: "miaoqing"},
    children: [
      {
        path: "/seedling",
        redirect: "/seedling/equip",
        name: "苗情监测",
        meta: {title: "苗情监测"}
      },
      {
        path: "/uvTelegraph",
        redirect: "/uvTelegraph/equip",
        name: "远程智慧农业监测仪",
        meta: {title: "远程智慧农业监测仪"}
      }
    ]
  },
  // 墒情监测
  {
    path: "/lyrical_",
    component: Layout,
    meta: {title: "墒情监测", icon: "lyrical"},
    children: [
      {
        path: "/lyrical",
        redirect: "/lyrical/equip",
        name: "墒情监控",
        meta: {title: "墒情监控"}
      }
    ]
  },
  // 灾情监测
  {
    path: "/weather_",
    component: Layout,
    meta: {title: "灾情监测", icon: "el"},
    children: [
      {
        path: "/weather",
        redirect: "/weather/equip",
        name: "气象站",
        meta: {title: "气象站"}
      },
      {
        path: "/waterMonitor",
        redirect: "/waterMonitor/equip",
        name: "水质监测",
        meta: {title: "水质监测"}
      }
    ]
  },
  // 农机管理
  // {
  //   path: "/weatherd",
  //   component: Layout,
  //   meta: { title: "农机系统", icon: "weather" },
  //   children: [
  //     {
  //       // eslint-disable-next-line no-undef
  //       path:
  //         "http://amm.cdbeyond.com/#/beyond?userId=" +
  //         sessionStorage.getItem("userId"),
  //       // path: 'http://localhost:8000/#/beyond?type=beyond&userId=' + sessionStorage.getItem('userId'),
  //       meta: { title: "农机管理" }
  //     }
  //   ]
  // },
  // 溯源系统
  // {
  //   path: "/wf_",
  //   component: Layout,
  //   meta: { title: "溯源系统", icon: "weather" },
  //   children: [
  //     {
  //       path: "http://101.207.7.7:8002/trace/login",
  //       meta: { title: "溯源系统" }
  //     }
  //   ]
  // },
  // 打药系统
  // {
  //   path: '/sprayInsecticide_',
  //   component: Layout,
  //   meta: { title: '打药系统', icon: 'dyxt' },
  //   children:[
  //     {
  //       path: '/sprayInsecticide',
  //       redirect: '/sprayInsecticide/index',
  //       name: '打药系统',
  //       meta: { title: '打药系统' }
  //     }
  //   ]
  // },

  // 智慧树
  {
    path: '/wisdomTree_',
    component: Layout,
    meta: {title: '其他设备', icon: 'el'},
    hidden: true,
    children: [
      {
        path: '/wisdomTree',
        redirect: '/wisdomTree/equip',
        name: '设备管理',
        meta: {title: '设备管理', icon: 'el'}
      },

      // {
      //   path: '/flowRateCardManage',
      //   redirect: '/flowRateCardManage/equip',
      //   name: '流量卡管理',
      //   meta: { title: '流量卡管理', icon: 'el' }
      // },
      // {
      //   path: "/birdTrap",
      //   redirect: "/birdTrap/equip",
      //   name: "捕鸟器",
      //   meta: { title: "捕鸟器" }
      // }
    ]
  },

  // 云南项目验收所加菜单
  {
    path: "/pzykxt",
    component: Layout,
    redirect: "/pzykxt/equip",
    name: "频振诱控系统",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/otherEquip/pzykxt.vue")
      },
    ]
  },
  {
    path: "/tdfkxt",
    component: Layout,
    redirect: "/tdfkxt/equip",
    name: "天敌防控系统",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/otherEquip/tdfkxt.vue")
      },
    ]
  },
  {
    path: "/wswpwxt",
    component: Layout,
    redirect: "/wswpwxt/equip",
    name: "微生物喷雾系统",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/otherEquip/wswpwxt.vue")
      },
    ]
  },
  {
    path: "/ssnyckxt",
    component: Layout,
    redirect: "/ssnyckxt/equip",
    name: "设施农业测控系统",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/otherEquip/ssnyckxt.vue")
      },
    ]
  },


  // 流量卡管理 flowRateCardManage
  {
    path: "/flowRateCardManage",
    component: Layout,
    redirect: "/flowRateCardManage/equip",
    name: "流量卡管理",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/flowRateCardManage/index")
      },
    ]
  },
  // 报警系统
  {
    path: "/alarmL",
    component: alarmL,
    // meta: { title: '报警系统', icon: 'weather' },
    redirect: "/alarmL/config",
    children: [
      {
        path: "config",
        name: "预警配置",
        component: () => import("@/views/alarm/config")
      },
      {
        path: "mode",
        name: "预警方式配置",
        component: () => import("@/views/alarm/mode")
      },
      {
        path: "record",
        name: "历史预警记录",
        component: () => import("@/views/alarm/record")
      },
      {
        path: "alarmConfig",
        name: "预警配置",
        component: () => import("@/views/alarm/alarmConfig")
      }
    ]
  },
  {
    path: '/manageCenter',
    component: manageCenter,
    // meta: { title: '管理中心', icon: 'weather' },
    redirect: '/manageCenter/peopleManage',
    children: [
      {
        path: 'peopleManage',
        // redirect:'/peopleManage/index',
        name: '人员管理',
        meta: {title: '人员管理'},
        component: () => import('@/views/manageCenter/peopleManage')
      },
    ]
  },
  // 杀虫灯
  {
    path: "/lamp_",
    component: Layout,
    meta: {title: "杀虫灯管理系统", icon: "lamp"},
    children: [
      {
        path: "/lamp_un",
        redirect: "/lamp_un/equip",
        name: "普通杀虫灯",
        meta: {title: "普通杀虫灯"}
      },
      {
        path: "/lamp",
        redirect: "/lamp/equip",
        name: "远程数字化杀虫灯",
        meta: {title: "远程数字化杀虫灯"}
      },
      {
        path: "/flxLamp",
        redirect: "/flxLamp/equip",
        name: "分离式杀虫灯",
        meta: {title: "分离式杀虫灯"}
      },
    ]
  },

  // 其他功能
  // {
  //   path: '/otherFunctions_',
  //   component: Layout,
  //   meta: { title: '其他功能', icon: 'qtgn' },
  //   children:[
  //     {
  //       path: '/otherFunctions',
  //       redirect: '/otherFunctions/index',
  //       name: '其他功能',
  //       meta: { title: '其他功能' }
  //     }
  //   ]
  // },

  // 页面路由
  // 打药系统
  {
    path: "/sprayInsecticide",
    component: Layout,
    redirect: "/sprayInsecticide/index",
    name: "打药系统",
    children: [
      {
        path: "/sprayInsecticide/index",
        name: "设备列表",
        component: () => import("@/views/sprayInsecticide/index")
      }
    ]
  },

  // 其他功能
  {
    path: "/otherFunctions",
    component: Layout,
    redirect: "/otherFunctions/index",
    name: "其他功能",
    children: [
      {
        path: "/otherFunctions/index",
        name: "设备列表",
        component: () => import("@/views/otherFunctions/index")
      }
    ]
  },
  // 远程信息化虫情测报系统
  {
    path: "/worms",
    component: Layout,
    redirect: "/worms/equip",
    name: "远程信息化虫情测报系统",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/worms/index")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/worms/map")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/worms/analysis")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/worms/pic")
      },

      {
        path: "notice",
        name: "虫情通告",
        component: () => import("@/views/worms/notice")
      },
      {
        path: "forecast",
        name: "虫情预警防治",
        component: () => import("@/views/worms/forecast")
      },
      {
        path: "briefing",
        name: "简报",
        hidden: true,
        component: () => import("@/views/worms/briefing")
      },
      {
        path: "test",
        name: "比例尺",
        hidden: true,
        component: () => import("@/views/worms/ruler")
      },
      {
        path: "picInfo",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/worms/picInfo")
      },
      {
        path: "picInfoNew",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/worms/picInfoNew.vue")
      }
    ]
  },
  // 远程智慧虫情测报仪
  {
    path: "/worms_dfs",
    component: Layout,
    redirect: "/worms_dfs/equip",
    name: "远程智慧虫情测报仪",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/worms_dfs/index")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/worms_dfs/map")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/worms_dfs/analysis")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/worms_dfs/pic")
      },

      {
        path: "notice",
        name: "虫情通告",
        component: () => import("@/views/worms_dfs/notice")
      },
      {
        path: "forecast",
        name: "虫情预警防治",
        component: () => import("@/views/worms_dfs/forecast")
      },
      {
        path: "track",
        name: "轨迹展示",
        hidden: true,
        component: () => import("@/views/worms_dfs/track")
      },
      {
        path: "briefing",
        name: "简报",
        hidden: true,
        component: () => import("@/views/worms_dfs/briefing")
      },
      {
        path: "test",
        name: "比例尺",
        hidden: true,
        component: () => import("@/views/worms_dfs/ruler")
      },
      {
        path: "picInfo",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/worms_dfs/picInfo")
      }
    ]
  },
  // 远程昆虫性诱测报仪
  {
    path: "/sexTelegraph",
    component: Layout,
    redirect: "/sexTelegraph/equip",
    name: "远程昆虫性诱测报仪",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/sexTelegraph/index")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/sexTelegraph/map")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/sexTelegraph/analysis")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/sexTelegraph/pic")
      },

      {
        path: "notice",
        name: "虫情通告",
        component: () => import("@/views/sexTelegraph/notice")
      },
      {
        path: "forecast",
        name: "虫情预警防治",
        component: () => import("@/views/sexTelegraph/forecast")
      },
      {
        path: "briefing",
        name: "简报",
        hidden: true,
        component: () => import("@/views/sexTelegraph/briefing")
      },
      {
        path: "test",
        name: "比例尺",
        hidden: true,
        component: () => import("@/views/sexTelegraph/ruler")
      },
      {
        path: "picInfo",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/sexTelegraph/picInfo")
      },
      {
        path: "picInfoNew",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/sexTelegraph/picInfoNew")
      }
    ]
  },
  // 远程智慧农业监测仪
  {
    path: "/uvTelegraph",
    component: Layout,
    redirect: "/uvTelegraph/equip",
    name: "远程智慧农业监测仪",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/uvTelegraph/index")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/uvTelegraph/pic")
      },
      {
        path: "video",
        name: "视频监控",
        component: () => import("@/views/uvTelegraph/videoList")
      },
      {
        path: "map",
        name: "地图展示",
        hidden: true,
        component: () => import("@/views/uvTelegraph/map")
      },
      {
        path: "analysis",
        name: "数据分析",
        hidden: true,
        component: () => import("@/views/uvTelegraph/analysis")
      },
      {
        path: "notice",
        name: "虫情通告",
        hidden: true,
        component: () => import("@/views/uvTelegraph/notice")
      },
      {
        path: "forecast",
        name: "虫情预警防治",
        hidden: true,
        component: () => import("@/views/uvTelegraph/forecast")
      },
      {
        path: "briefing",
        name: "简报",
        hidden: true,
        component: () => import("@/views/uvTelegraph/briefing")
      },
      {
        path: "test",
        name: "比例尺",
        hidden: true,
        component: () => import("@/views/uvTelegraph/ruler")
      },
      {
        path: "picInfo",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/uvTelegraph/picInfo")
      }
    ]
  },
  // 稻水象甲监测仪
  {
    path: "/dsxj",
    component: Layout,
    redirect: "/dsxj/equip",
    name: "稻水象甲监测仪",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/dsxj/index")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/dsxj/map")
      },

      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/dsxj/analysis")
      }
    ]
  },
  // 烟甲虫监测仪
  {
    path: "/yqc",
    component: Layout,
    redirect: "/yqc/equip",
    name: "烟甲虫监测仪",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/yqc/index")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/yqc/pic")
      },
      {
        path: "picInfo",
        name: "识别详细",
        hidden: true,
        component: () => import("@/views/yqc/picInfo")
      }
    ]
  },
  // 远程杀虫灯
  {
    path: "/lamp",
    component: Layout,
    redirect: "/lamp/equip",
    name: "远程杀虫灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/lamp/deviceList")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/lamp/map")
      },

      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/lamp/analysis")
      },
      {
        path: "itemEquip",
        name: "实时状态",
        component: () => import("@/views/lamp/itemEquip")
      },
      {
        path: "warning",
        name: "杀虫预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },

  // 普通杀虫灯
  {
    path: "/lamp_un",
    hidden: true,
    component: Layout,
    redirect: "/lamp_un/equip",
    name: "普通杀虫灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/lamp_un/deviceList")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/lamp_un/map")
      },

      {
        path: "analysis",
        name: "数据分析",
        hidden: true,
        component: () => import("@/views/lamp_un/analysis")
      },
      {
        path: "warning",
        name: "杀虫预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },
  // 分离式杀虫灯 flxLamp
  {
    path: "/flxLamp",
    hidden: true,
    component: Layout,
    redirect: "/flxLamp/equip",
    name: "分离式杀虫灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/flsLamp/equip")
      },
      {
        path: "picList",
        name: "图片列表",
        component: () => import("@/views/flsLamp/picList")
      },
      {
        path: "controlMode",
        name: "控制模式",
        component: () => import("@/views/flsLamp/controlMode")
      },
      {
        path: 'analysis',
        name: '数据分析',
        component: () => import('@/views/flsLamp/analysis')
      },
    ]
  },


  // 苗情监控
  {
    path: "/seedling",
    component: Layout,
    redirect: "/seedling/equip",
    name: "苗情监控",
    children: [
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/tree/index"),
        hidden: true
      },
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/seedlingGrowth/deviceList")
      },
      {
        path: "video",
        name: "视频监控",
        component: () => import("@/views/seedlingGrowth/videoList")
      },
      {
        path: "pic",
        name: "图片列表",
        component: () => import("@/views/seedlingGrowth/imgList")
      },
      {
        path: "picInfo",
        name: "图片展示",
        hidden: true,
        component: () => import("@/views/seedlingGrowth/imgInfo")
      },
      // {
      //   path: "pic_odd",
      //   name: "异常图片",
      //   hidden: false,
      //   component: () => import("@/views/seedlingGrowth/imgListAB")
      // },
      {
        path: "pic_odd_info",
        name: "异常图片",
        hidden: true,
        component: () => import("@/views/seedlingGrowth/imgInfoAB")
      },

      {
        path: "warning",
        name: "苗情预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },
  // 墒情监控
  {
    path: "/lyrical",
    component: Layout,
    redirect: "/lyrical/equip",
    name: "墒情监控",
    children: [
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/table/index"),
        hidden: true
      },
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/lyrical/equip")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/lyrical/analysis")
      },
      {
        path: "warning",
        name: "墒情预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },
  // 气象站
  {
    path: "/weather",
    hidden: true,
    component: Layout,
    redirect: "/weather/equip",
    name: "气象站",
    children: [

      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/meteorological/deviceList")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/meteorological/map"),
        hidden: false
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/meteorological/analysis")
      },
      {
        path: "warning",
        name: "气象预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },
  // 水质监测 waterMonitor
  {
    path: "/waterMonitor",
    component: Layout,
    redirect: "/waterMonitor/equip",
    name: "水质监测",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/waterMonitor/deviceList")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/waterMonitor/analysis")
      }
    ]
  },

  // 高空测报灯
  {
    path: "/high",
    component: Layout,
    redirect: "/high/equip",
    name: "高空测报灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/high/index")
      },

      {
        path: "map",
        name: "地图展示",
        hidden: false,
        component: () => import("@/views/high/map")
      },
      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/high/analysis")
      },
      {
        path: "picList",
        name: "图片列表",
        hidden: false,
        component: () => import("@/views/high/picList")
      },
      {
        path: "picInfo",
        name: "图片详情",
        hidden: true,
        component: () => import("@/views/high/picInfo")
      }
    ]
  },
  // 水肥灌溉
  {
    path: "/wf",
    component: Layout,
    redirect: "/wf/equip",
    name: "水肥灌溉",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/wf/index")
      },
      {
        path: "control",
        name: "远程控制",
        hidden: true,
        component: () => import("@/views/wf/control")
      }
    ]
  },
  // 电子性诱测报灯
  {
    path: "/el",
    component: Layout,
    redirect: "/lamp/equip",
    name: "电子性诱测报灯",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/electronic_lure/deviceList")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/electronic_lure/map")
      },

      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/electronic_lure/analysis")
      },
      {
        path: "warning",
        name: "杀虫预警",
        hidden: true,
        component: () => import("@/views/tree/index")
      }
    ]
  },
  // 环境友好型杀虫灯
  {
    path: "/environment_friendly",
    component: Layout,
    meta: {title: "环境友好型杀虫灯", icon: "hj"},
    children: [
      {
        path: "/friendly",
        redirect: "/friendly/equip",

        meta: {title: "环境友好型杀虫灯", icon: "hj"}
      }
    ]
  },
  // 捕鸟器  三江项目
  {
    path: "/birdTrap",
    component: Layout,
    redirect: "/birdTrap/equip",
    name: "捕鸟器",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/birdTrap/deviceList")
      },
    ]
  },
  //智慧树
  {
    path: "/wisdomTree",
    component: Layout,
    redirect: "/wisdomTree/equip",
    name: "智慧树",
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/wisdomTree/index")
      },
      {
        path: "picList",
        name: "图片列表",
        component: () => import("@/views/wisdomTree/picList")
      },
      {
        path: "map",
        name: "地图展示",
        component: () => import("@/views/wisdomTree/map")
      },
      {
        path: "liveVideo",
        name: "视频监控",
        component: () => import("@/views/wisdomTree/liveVideo")
      },

      {
        path: "analysis",
        name: "数据分析",
        component: () => import("@/views/wisdomTree/analysis")
      },
      {
        path: "picInfo",
        name: "识别详情",
        hidden: true,
        component: () => import("@/views/wisdomTree/picInfo")
      }
    ]
  },

  // 孢子捕捉仪
  {
    path: "/spore",
    component: Layout,
    redirect: "/spore/map",
    name: "孢子捕捉仪",
    // meta: { title: '孢子捕捉仪', icon: 'example' },
    // hidden: true,
    children: [
      {
        path: "equip",
        name: "设备列表",
        component: () => import("@/views/spore/equipList")
      },
      // {
      //   path: 'map',
      //   name: '地图展示',
      //   component: () => import('@/views/table/index')
      // },

      {
        path: "picList",
        name: "图片列表",
        component: () => import("@/views/spore/picList")
      },
      {
        path: "picInfo",
        name: "孢子捕获仪",
        hidden: true,
        component: () => import("@/views/spore/picInfo")
      }
      // {
      //   path: 'warning',
      //   name: '孢子预警',
      //   component: () => import('@/views/tree/index2')
      // }
    ]
  },
  // {
  //     path: '/sys',
  //     component: Layout,
  //     redirect: '/sys/role',
  //     name: '系统设置',
  //     meta: { title: '系统设置', icon: 'setting' },
  //     children: [
  //         {
  //             path: 'role',
  //             name: '角色管理',
  //             component: () => import('@/views/sys/role'),
  //             meta: { title: '角色管理', icon: 'table' }
  //         },
  //         {
  //             path: 'dept',
  //             name: '部门管理',
  //             component: () => import('@/views/sys/dept'),
  //             meta: { title: '部门管理', icon: 'tree' }
  //         },
  //         {
  //             path: 'mem',
  //             name: '人员管理',
  //             component: () => import('@/views/sys/mem'),
  //             meta: { title: '人员管理', icon: 'tree' }
  //         },
  //         {
  //             path: 'auth',
  //             name: '权限管理',
  //             component: () => import('@/views/sys/auth'),
  //             meta: { title: '权限管理', icon: 'tree' }
  //         },
  //         {
  //             path: 'set',
  //             name: '系统设置',
  //             component: () => import('@/views/sys/set'),
  //             meta: { title: '系统设置', icon: 'tree' }
  //         }
  //     ]
  // },
  // {
  //     path: '/help',
  //     component: Layout,
  //     redirect: '/help/1',
  //     name: '帮助中心',
  //     meta: { title: '帮助中心', icon: 'help' },
  //     children: [
  //         {
  //             path: '1',
  //             name: '帮助1',
  //             component: () => import('@/views/table/index'),
  //             meta: { title: 'Table', icon: 'table' }
  //         },
  //         {
  //             path: '2',
  //             name: '帮助2',
  //             component: () => import('@/views/tree/index'),
  //             meta: { title: 'Tree', icon: 'tree' }
  //         }
  //     ]
  // },

  //   {
  //       path: '/form',
  //       redirect: '/form/index',
  //       component: Layout,
  //       name: '表单',
  //       meta: { title: '表单', icon: 'example' },
  //       children: [
  //           {
  //               path: 'index',
  //               name: 'Form',
  //               component: () => import('@/views/form/index'),
  //               meta: { title: 'Form', icon: 'form' }
  //           }
  //       ]
  //   },
  // {
  //   path: '/nested',
  //   component: Layout,
  //   redirect: '/nested/menu1',
  //   name: 'Nested',
  //   meta: {
  //     title: 'Nested',
  //     icon: 'nested'
  //   },
  //   children: [
  //     {
  //       path: 'menu1',
  //       component: () => import('@/views/nested/menu1/index'), // Parent router-view
  //       name: 'Menu1',
  //       meta: { title: 'Menu1' }
  //     },
  //     {
  //       path: 'menu2',
  //         name: 'Menu2',
  //       component: () => import('@/views/nested/menu2/index'),
  //       meta: { title: 'menu2' }
  //     }
  //   ]
  // },
  //
  // {
  //   // path: 'external-link',
  //   component: Layout,
  //   name:"外链",
  //   meta: { title: '表单', icon: 'example' },
  //   path: 'https://panjiachen.github.io/vue-element-admin-site/#/'
  // },

  // 404 page must be placed at the end !!!   //404页面必须放置在末尾
  {path: "*", redirect: "/404", hidden: true}
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// router.addRoutes(asyncRoutes);
export default router;
