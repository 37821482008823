import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/home/index',
    name: '比昂科技',
    children: [{
      path: 'dashboard',
      name: '比昂科技',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '比昂科技', icon: 'dashboard' }
    }]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  {
    path: '/home',
    component: Layout,
    redirect: '/home/index',
    name: '首页',
    meta: { title: '首页', icon: 'home' },
    children: [
      {
        path: 'index',
        name: 'home',
        component: () => import('@/views/table/index')
      }
    ]
  },
  {
    path: '/worms',
    component: Layout,
    redirect: '/worms/equip',
    name: '虫情测报',
    meta: { title: '虫情测报', icon: 'monitor' },
    children: [
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/table/index')
      },
      {
        path: 'pic',
        name: '图片列表',
        component: () => import('@/views/table/index')
      },
      {
        path: 'pic_show',
        name: '图片展示',
        component: () => import('@/views/table/index')
      },
      {
        path: 'analysis',
        name: '数据分析',
        component: () => import('@/views/table/index')
      },
      {
        path: 'forecast',
        name: '虫情预警防治',
        component: () => import('@/views/table/index')
      },
      {
        path: 'briefing',
        name: '简报',
        component: () => import('@/views/table/index')
      },
      {
        path: 'test',
        name: '标尺',
        component: () => import('@/views/table/index')
      }
    ]
  },
  {
    path: '/lamp',
    component: Layout,
    redirect: '/lamp/map',
    name: '杀虫灯',
    meta: { title: '杀虫灯', icon: 'lamp' },
    children: [
      {
        path: 'map',
        name: '地图展示',
        component: () => import('@/views/lamp/map')
      },
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/lamp/deviceList')
      },
      {
        path: 'analysis',
        name: '数据分析',
        component: () => import('@/views/lamp/analysis')
      },
      {
        path: 'warning',
        name: '杀虫预警',
        component: () => import('@/views/tree/index')
      }
    ]
  },
  {
    path: '/seedling',
    component: Layout,
    redirect: '/seedling/map',
    name: '苗情监控',
    meta: { title: '苗情监控', icon: 'miaoqing' },
    children: [
      {
        path: 'map',
        name: '地图展示',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/seedlingGrowth/deviceList')
      },
      {
        path: 'pic',
        name: '图片列表',
        component: () => import('@/views/seedlingGrowth/imgList')
      },
      {
        path: 'picInfo',
        name: '图片展示',
        hidden: true,
        component: () => import('@/views/seedlingGrowth/imgInfo')
      },
      {
        path: 'pic_odd',
        name: '异常图片',
        component: () => import('@/views/seedlingGrowth/imgList')
      },
      {
        path: 'video',
        name: '视频监控',
        component: () => import('@/views/seedlingGrowth/videoList')
      },
      {
        path: 'warning',
        name: '苗情预警',
        component: () => import('@/views/tree/index')
      }
    ]
  },
  {
    path: '/lyrical',
    component: Layout,
    redirect: '/lyrical/map',
    name: '墒情监控',
    meta: { title: '墒情监控', icon: 'example' },
    children: [
      {
        path: 'map',
        name: '地图展示',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/lyrical/equip')
      },
      {
        path: 'analysis',
        name: '数据分析',
        component: () => import('@/views/lyrical/analysis')
      },
      {
        path: 'warning',
        name: '墒情预警',
        component: () => import('@/views/tree/index')
      }
    ]
  },
  {
    path: '/weather',
    component: Layout,
    redirect: '/weather/map',
    name: '气象站',
    meta: { title: '气象站', icon: 'example' },
    children: [
      {
        path: 'map',
        name: '地图展示',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/meteorological/deviceList')
      },
      {
        path: 'analysis',
        name: '数据分析',
        component: () => import('@/views/meteorological/analysis')
      },
      {
        path: 'warning',
        name: '气象预警',
        component: () => import('@/views/tree/index')
      }
    ]
  },
  {
    path: '/spore',
    component: Layout,
    redirect: '/spore/map',
    name: '孢子捕捉仪',
    meta: { title: '孢子捕捉仪', icon: 'example' },
    children: [
      {
        path: 'map',
        name: '地图展示',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'equip',
        name: '设备列表',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'pic',
        name: '图片列表',
        component: () => import('@/views/tree/index')
      },
      {
        path: 'warning',
        name: '孢子预警',
        component: () => import('@/views/tree/index')
      }
    ]
  },
  {
    path: '/sys',
    component: Layout,
    redirect: '/sys/role',
    name: '系统设置',
    meta: { title: '系统设置', icon: 'setting' },
    children: [
      {
        path: 'role',
        name: '角色管理',
        component: () => import('@/views/table/index'),
        meta: { title: '角色管理', icon: 'table' }
      },
      {
        path: 'dept',
        name: '部门管理',
        component: () => import('@/views/table/index'),
        meta: { title: '部门管理', icon: 'tree' }
      },
      {
        path: 'mem',
        name: '人员管理',
        component: () => import('@/views/table/index'),
        meta: { title: '人员管理', icon: 'tree' }
      },
      {
        path: 'auth',
        name: '权限管理',
        component: () => import('@/views/table/index'),
        meta: { title: '权限管理', icon: 'tree' }
      },
      {
        path: 'set',
        name: '系统设置',
        component: () => import('@/views/table/index'),
        meta: { title: '系统设置', icon: 'tree' }
      }
    ]
  },
  {
    path: '/help',
    component: Layout,
    redirect: '/help/1',
    name: '帮助中心',
    meta: { title: '帮助中心', icon: 'help' },
    children: [
      {
        path: '1',
        name: '帮助1',
        component: () => import('@/views/table/index'),
        meta: { title: 'Table', icon: 'table' }
      },
      {
        path: '2',
        name: '帮助2',
        component: () => import('@/views/tree/index'),
        meta: { title: 'Tree', icon: 'tree' }
      }
    ]
  },

  //   {
  //       path: '/form',
  //       redirect: '/form/index',
  //       component: Layout,
  //       name: '表单',
  //       meta: { title: '表单', icon: 'example' },
  //       children: [
  //           {
  //               path: 'index',
  //               name: 'Form',
  //               component: () => import('@/views/form/index'),
  //               meta: { title: 'Form', icon: 'form' }
  //           }
  //       ]
  //   },
  // {
  //   path: '/nested',
  //   component: Layout,
  //   redirect: '/nested/menu1',
  //   name: 'Nested',
  //   meta: {
  //     title: 'Nested',
  //     icon: 'nested'
  //   },
  //   children: [
  //     {
  //       path: 'menu1',
  //       component: () => import('@/views/nested/menu1/index'), // Parent router-view
  //       name: 'Menu1',
  //       meta: { title: 'Menu1' }
  //     },
  //     {
  //       path: 'menu2',
  //         name: 'Menu2',
  //       component: () => import('@/views/nested/menu2/index'),
  //       meta: { title: 'menu2' }
  //     }
  //   ]
  // },
  //
  // {
  //   // path: 'external-link',
  //   component: Layout,
  //   name:"外链",
  //   meta: { title: '表单', icon: 'example' },
  //   path: 'https://panjiachen.github.io/vue-element-admin-site/#/'
  // },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
// router.addRoutes(asyncRoutes);
export default router
