<template>
  <section class="app-main">
    <!-- 过渡动画 -->

    <transition name="fade-transform" mode="out-in">
      <keep-alive :max="10" :include="includeRoute">
        <router-view v-if="!includePaths.includes($route.path)" :key="key"/>
        <router-view v-else="includePaths.includes($route.path)"/>
      </keep-alive>
    </transition>

  </section>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {
      // WormsAnalysis', , 'SexTelegraphAnalysis'
      includeRoute: ['WormsPic', 'SexTelegraphPic'],
      // '/worms/analysis',, '/sexTelegraph/analysis'
      includePaths: ['/worms/pic',  '/sexTelegraph/pic'],
    }
  },
  computed: {
    key() {
      return this.$route.path + new Date().getTime();
    },
  },
  watch: {
    '$route'(to, from) {
      console.log('AppMain watch $route', to, from)
    }
  }
};
</script>

<style scoped lang="scss">
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 151px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #eeeff3;
  padding: 20px 25px;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.alarm_lock .app-container {
  max-width: 1200px;
  margin: auto;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
