import axios from 'axios'
// import axios from '@/utils/axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { baseURL } from '@/config'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url

  baseURL, // url = base url + request url

  // baseURL,
  withCredentials: true, // send cookies when cross-domain requests  跨域请求时发送cookie
  timeout: 50000 ,// request timeout  请求超时
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // }
})

// console.log("getToken()  请求头",getToken());
// request interceptor  请求拦截器
service.interceptors.request.use(config => {
  console.log('config',config)
    // do something before request is sent  让每个请求携带令牌
    if ((getToken()||store.getters.token) && !config.url.includes('findByBigKey')) {
      // let each request carry token
      // ['X-Token'] is a custom headers key  自定义标题键
      // please modify it according to the actual situation  请根据实际情况进行修改
      config.headers['Authorization'] = config.headers['Authorization'] ? config.headers['Authorization'] : 'Bearer ' + getToken()
      config.headers.post = { 'Content-Type': 'application/json' }
    }
    // console.log(config,"config")
    return config
  },error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor  响应拦截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status    如果您想要获取http信息，如头信息或状态信息
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code  通过自定义代码确定请求状态
   * Here is just an example
   * You can also judge the status by HTTP Status Code    您还可以通过HTTP状态代码判断状态
   */

  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.  如果自定义代码不是20000，就会被判定为错误。


    if (res.code !== 200 && res.code !== 500) {
          Message({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
          })



      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;  非法令牌;50012:已登录其他客户端;50014:令牌过期;
      if (res.code === 401) {
        // to re-login
        MessageBox.confirm('您的登录信息失效或非法，请重新登录！', '登录失效', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      if(res.code == 500){
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return res
    }
  },error => {
    Message({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    })

    return Promise.reject(error)
  }
)

export default service
