// import requestMock from '@/utils/requestMock'
import request from '@/utils/request'
const userBase = 'fourmood-web'
const userBase2 = 'lamp-rest'
// import axios from 'axios'



// 获得设备信息和实时状态
export function getInfoStatus(params){
  return request({
    url : userBase + "/forecast/getDecNum",
    method:'GET',
    params
  })
}


// /*获得设备列表*/
export function getDev(params) {
  return request({
    url: userBase + '/forecast/query',
    method: 'POST',
    data: params
  })
}
// /*获得设备列表*/
export function addDev(params) {
  return request({
    url: userBase + '/forecast/save',
    method: 'POST',
    data: params
  })
}
export function getAllBase(params) {
  return request({
    url: userBase + '/accessUsernames',
    method: 'GET',
    data: params
  })
}
// /*获得设备下拉*/
export function getBase(params) {
  return request({
    url: userBase + '/forecast/myOwnerDevice',
    method: 'GET',
    params
  })
}
// /*设置设备别名*/
export function setAlias(params) {
  return request({
    url: userBase + '/forecast/alias',
    method: 'POST',
    params
  })
}
// /*设置设备名称*/
export function setName(params) {
  return request({
    url: userBase + '/forecast/editName',
    method: 'POST',
    params
  })
}
// /*获得设备图片列表*/
export function getPicList(params) {
  return request({
    url: userBase + '/forecast/photo/find',
    method: 'POST',
    data: params
  })
}

// /*根据id删除图片*/
export function delPic(id) {
    return request({
      url: userBase + '/forecast/photo/removePhoto?photoId='+id,
      method: 'POST',
    //   data: params
    })
  }

// /*单独虫情曲线分析图*/
export function getStatistics(params) {
  return request({
    url: userBase + '/forecast/statistics',
    method: 'GET',
    params
  })
}
// /*获取所有分析数据*/
export function getAna(params) {
  return request({
    url: userBase + '/forecast/allStatistics',
    method: 'GET',
    params
  })
}

// 获取所有设备分析数据--首页 /forecast/allDeviceStatistics
export function getIndexData(params) {
  return request({
    url: userBase + '/forecast/allDeviceStatistics',
    method: 'GET',
    params,
  })
}

// /*害虫列表*/
export function getInsects(params) {
    return request({
      url: userBase + '/forecast/photo/insects',
      method: 'GET',
      params
    })
}

// /*害虫列表--自己的接口*/
export function getInsects2(params) {
    return request({
      url: userBase + '/insect/info/list',
      method: 'POST',
      data:params
    })
  }

// /*下载数据图表*/
export function downLoadTable(params) {
  // console.log(request)
  window.location.href = request.defaults.baseURL + userBase + '/statistics/export?access_token=' + sessionStorage.getItem('access_token') +
        '&exportType=FORECAST&type=' + params.type +"&imei="+params.imei
}
// /*设备反向控制*/
export function antiControl(params) {
  return request({
    url: userBase + '/forecast/control/new',
    method: 'POST',
    data: params
  })
}
// /*识别详情1*/
export function pInfo(params) {
  return request({
    url: userBase + '/forecast/photo/insects',
    method: 'POST',
    data: params
  })
}
// /*识别详情2*/
export function pInfo2(params) {
  return request({
    url: userBase + '/forecast/photo/pest/find/' + params.id,
    method: 'GET',
    data: params
  })
}
// /*添加虫情记录*/
export function addDot(params) {
  return request({
    url: userBase + '/forecast/photo/pest/add',
    method: 'POST',
    data: params
  })
}
// /*删除虫情记录*/
export function deleteDot(params) {
  return request({
    url: userBase + '/forecast/photo/pest/delete/' + params.id,
    method: 'POST',
    data: params
  })
}
// 高空测报删除虫情记录
export function deleteDot2(params) {
  return request({
    url: userBase + '/forecast/photo/pest/deleteRecord',
    method: 'POST',
    data: params
  })
}

// /*确认审核统计*/
export function confirmPest(params) {
  return request({
    url: userBase + '/forecast/photo/pest/confirm',
    method: 'POST',
    data: params
  })
}
// /*首页设备状态统计*/
export function indexHeader(params) {
  return request({
    url: userBase + '/index/header',
    method: 'GET',
    data: params
  })
}
// /*首页图表虫情识别*/
export function indexStatis(params) {
  return request({
    url: userBase + '/index/statistics',
    method: 'GET',
    data: params
  })
}
/* 虫情预警防治-查询条件*/
export function preQuery(params) {
  return request({
    url: userBase + '/record/getCrop',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-查询默认值*/
export function preDefault(params) {
  return request({
    url: userBase + '/record/getDefaultData',
    method: 'POST',
    params
  })
}
/* 虫情预警防治-查询预测结果*/
export function preResult(params) {
  return request({
    url: userBase + '/record/getResult',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-查询预测结果历史记录*/
export function preHistory(params) {
  return request({
    url: userBase + '/record/queryPage',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-保存预测结果*/
export function preSave(params) {
  return request({
    url: userBase + '/record/saveResult',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告联系人*/
export function noticeLink(params) {
  return request({
    url: userBase + '/contact/index',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告组列表*/
export function noticeGroup(params) {
  return request({
    url: userBase + '/contact/mygroup',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告-新增联系人*/
export function noticeAddMan(params) {
  return request({
    url: userBase + '/contact/addman',
    method: 'POST',
    data: params
  })
}/* 虫情预警防治-虫情通告-新增群组*/
export function noticeAddGroup(params) {
  return request({
    url: userBase + '/contact/addgroup',
    method: 'POST',
    data: params
  })
}/* 虫情预警防治-虫情通告-移动联系人到群组*/
export function noticeAddToGroup(params) {
  return request({
    url: userBase + '/contact/addmangroup',
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告-删除联系人*/
export function noticeDelMan(params) {
  return request({
    url: userBase + '/contact/delete/' + params.id,
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告-删除群组*/
export function noticeDelGroup(params) {
  return request({
    url: userBase + '/contact/del/group/' + params.id,
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-虫情通告-删除群组*/
export function noticeDelGroupMan(params) {
  return request({
    url: userBase + '/contact/remove?id=' + params.id,
    method: 'POST',
    data: params
  })
}
/* 虫情预警防治-发送短信*/
export function noticeSendSms(params) {
  return request({
    url: userBase + '/record/sendMeasure',
    method: 'POST',
    params
  })
}

/* 虫情预警防治-发送短信*/
export function noticeSendMsg(params) {
    return request({
      url: userBase + '/record/sendMeasure2',
      method: 'POST',
      data:params
    })
}

/* 虫情预警防治-发送邮件*/
export function noticeSendEmail(params) {
  return request({
    url: userBase + '/record/sendEmail',
    method: 'POST',
    params
  })
}
/* 增值服务-虫情识别-配置*/
export function _vipBug(params) {
  return request({
    url: userBase + '/forecast/behavior',
    method: 'GET',
    params
  })
}
/* 增值服务-虫情识别-配置-添加*/
export function _vipBugAdd(params) {
  return request({
    url: userBase + '/forecast/exbehavior',
    method: 'POST',
    data: params
  })
}
/* 反控展示信息*/
export function _controlInfo(params) {
  return request({
    url: userBase + '/forecast/current',
    method: 'GET',
    params
  })
}
/* 分配人员*/
export function _guild(params) {
  return request({
    url: userBase + '/forecast/alloc',
    method: 'POST',
    params
  })
}
/* 分配人员*/
export function _cboard(params) {
  return request({
    url: userBase + '/forecast/changeBoard',
    method: 'POST',
    params
  })
}


// 上传图片地址接口
export function uploadAdd(params) {
  return request({
    url: userBase + '/push/Attach',
    method: 'POST',
    params
  })
}


// 友好型杀虫灯
//   查看设备的虫情配置
export function findConfig(params) {
  return request({
    url: userBase + '/insect_monitor/find',
    method: 'POST',
    params
  })
}

// 友好型杀虫灯保存接口
export function saveConfig(params) {
  return request({
    url: userBase + '/insect_monitor/saveOrUpdate',
    method: 'POST',
    data:params
  })
}

// 得到作物的默认监测配置数据
export function getCropConfig(params) {
  return request({
    url: userBase + '/insect_monitor/get_crop_config',
    method: 'POST',
    data:params
  })
}


// 所有远程设备-按项目分组
export function getItemGroup(params) {
  return request({
    url: userBase2 + '/lamp/dids_group',
    method: 'GET',
    params
  })
}

// 杀虫灯详情批量查询接口
export function findLampDettails(params) {
  return request({
    url: userBase2 + '/lamp/popups',
    method: 'GET',
    params
  })
}


// /insect_monitor/query 环境友好型杀虫灯查询配置列表
export function getfriendlyList(params) {
  return request({
    url: userBase + '/insect_monitor/query',
    method: 'POST',
    data:params
  })
}

// /insect_monitor/statistics 虫情曲线分析图
export function InsectChart(params) {
  return request({
    url: userBase + '/insect_monitor/statistics',
    method: 'GET',
    params
  })
}

//  根据id删除配置信息
export function delConfig(params) {
  return request({
    url: userBase + '/insect_monitor/del?id='+params.id,
    method: 'POST',
    
  })
}

//  查询地图位置
export function getMapAdd(params) {
  return request({
    url: userBase + '/insect_monitor/mapData',
    method: 'GET',
    params
  })
}

// 轨迹坐标
export function getTrack(params) {
  return request({
    url: "fourmood-iot/dfs2/getTravels?"+params,
    // url: "fourmood-iot/dfs2/getTravels?did=33FFD6054248313233861157&start=2021-06-16&end=2021-06-17",
    method: 'GET',
    params
  })
}

// 信息化虫情测报系统——设备反控_信息展示查询
export function _equipSearch(params) {
  return request({
    url: "fourmood-iot/dfs2/getYccInfoOrDetail",
    method: 'POST',
    params
  })
}

// 信息化虫情测报系统 设备反控_信息展示刷新  _equipRefresh
export function _equipRefresh(params) {
  return request({
    url: "fourmood-iot/dfs2/getDBYccInfoOrDetail",
    method: 'POST',
    params
  })
}

// 反控  _equipControl
export function _equipControl(params) {
  return request({
    url: "fourmood-iot/dfs2/sendYccSetMsg",
    method: 'POST',
    params
  })
}

// 智慧虫情测报系统 查询
export function _equipSearch2(params) {
  return request({
    url: "fourmood-iot/dfs2/getDfs2DBDetail",
    method: 'POST',
    params
  })
}

// 智慧虫情测报系统_刷新
export function _equipRefresh2(params) {
  return request({
    url: "fourmood-iot/dfs2/findInfoOrDetail",
    method: 'POST',
    params
  })
}

// 稻飞虱2型设置参数接口 
export function _setParameter(params) {
  return request({
    url: "fourmood-iot/dfs2/sendSetMsg",
    method: 'POST',
    params
  })
}
// 测报灯抓拍 - 图片会存入测报灯图片表中
export function _manualPictures(params) {
  return request({
    url: "fourmood-iot/dfs2/forsCapture",
    method: 'GET',
    params
  })
}

// http://wechat.scyphr.com/v1/fourmood-iot/dfs2/getSex1DBDetail?did=34FFD3054247373311770357
// 实时状态
export const realTimeStatus = params => {
  return request({
    url: `/fourmood-iot/dfs2/getSex1DBDetail`,
    method: 'GET',
    params
  })
}

// 设备状态 在线离线
export const allKindOfEquipNum = params => {
  return request({
    url: userBase + '/index/header2',
    method: 'GET',
    params
  })
}

// 手动抓拍 http://wechat.scyphr.com/v1/fourmood-iot/dfs2/sendYccpPhoto?did=34FFDE05464E373710721843
export const _sdzp = params => {
  return request({
    url: 'fourmood-iot/dfs2/sendYccpPhoto',
    method: 'POST',
    params
  })
}