var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar 112233"},[_c('div',{staticClass:"nav-h"},[_c('hamburger',{staticClass:"hamburger-container",attrs:{"is-active":_vm.sidebar.opened},on:{"toggleClick":_vm.toggleSideBar}}),_c('div',{staticClass:"nav-sub"},[_c('div',{staticClass:"sub"},[(_vm.config.name)?_c('a',{on:{"click":_vm.goHome}},[_vm._v(_vm._s(_vm.config.name))]):_c('a',{on:{"click":_vm.goHome}},[_vm._v("绿色云农—比昂绿色防控"),_c('span',[_vm._v("AI")]),_vm._v("平台")])])]),_c('div',{staticClass:"right-menu"},[(_vm.account != '乃渠镇花椒园区'&&_vm.account != '三台县农业局植保站')?_c('div',{staticClass:"manageCenter",on:{"click":_vm.goManageCenter}},[_vm._v(" 管理中心 ")]):_vm._e(),_c('el-dropdown',{staticClass:"avatar-container",attrs:{"trigger":"click"}},[_c('div',{staticClass:"avatar-wrapper"},[_c('span',[_vm._v("指挥中心")]),_c('i',{staticClass:"el-icon-caret-bottom"})]),_c('el-dropdown-menu',{staticClass:"user-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('span',{staticStyle:{"display":"block"}},[_c('a',{attrs:{"target":"_blank","href":_vm.mapPath}},[_vm._v("地图防控")])])]),(
              _vm.account != '苍溪县陵江镇人民政府' &&
              _vm.account != '乃渠镇花椒园区' &&
              _vm.account != '古蔺县太平镇人民政府'&&_vm.account == 'cdbeyond'
            )?_c('el-dropdown-item',[_c('span',{staticStyle:{"display":"block"}},[_c('a',{attrs:{"target":"_blank","href":"http://iot.cdbeyond.com/crzx/index.html"}},[_vm._v("蒲江基地")])])]):_vm._e(),(
              _vm.account != '苍溪县陵江镇人民政府' &&
              _vm.account != '乃渠镇花椒园区' &&
              _vm.account != '古蔺县太平镇人民政府'&&_vm.account == 'cdbeyond'
            )?_c('el-dropdown-item',[_c('span',{staticStyle:{"display":"block"}},[_c('a',{attrs:{"target":"_blank","href":"http://iot.cdbeyond.com/green/index.html#/Home"}},[_vm._v("绿色防控中心")])])]):_vm._e(),(
              _vm.account != '乃渠镇花椒园区' && _vm.account != '古蔺县太平镇人民政府'
            )?_c('el-dropdown-item',[_c('span',{staticStyle:{"display":"block"}},[_c('a',{attrs:{"href":"#/alarmL/config"}},[_vm._v("报警系统")])])]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"fix_div"}),(_vm.homeKey)?_c('breadcrumb',{staticClass:"breadcrumb-container"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }